import axios from 'axios'
import state from '../state' 
import AX from 'axios'
const TK = localStorage.getItem('fusegToken')
AX.defaults.headers.common['Authorization'] = TK;

var states = state;
function HANDLE_ERROR(context,error){
    
    if (error.response) {
        if(error.response.status == 401) {
            console.log("got four o one error", error.response.data)
            context.dispatch('logout',error.response.data.error)
        }
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
        console.log('The request was made but no response was received')
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    return 0;
    // console.log(error.config);
}

    const getRegTypes = async () => {
        states.regTypes = [];
        states.regType = null;
    return await axios.get(states.URL+'/users/reg-types')
    .then((res) => {
        console.log('kyc types', res)
        if(res.data.status){
            states.regTypes = res.data
        res.data.data.forEach(element => { 
            if(element.name === "Enhanced AML") states.regTypeAML = element 
            if(element.name === "Standard AML") states.regType = element 
            if(element.name === "Id Only") states.regTypeId = element 
        }
            );
        return {status:true}
        }else{
            return {status:false}
        }
    })
    .catch((error) => {
        return HANDLE_ERROR(context,error)
    })
    }
    const verifyTfa = async ({commit}, data) => {
        console.log("verifyTfa data", data)
        data.userId = (states.user) ? states.user._id : null
        data.tfaStatus = (data.tfaStatus) ? data.tfaStatus : false;
        return await AX.post(states.URL+'/users/verify/tfa', data)
        .then((res) => {
            if(res.data.status == 1){
                states.user = res.data.user;
                return res.data
            }else if(res.data.status == null){
                return res.data
            }else{
            return res.data;
            }
            // console.log('2FA verify response ', res)
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
        })
    }
    const setTFA = async ({commit}, reset) => {
        let data = {}
        data.userId = (states.user) ? states.user._id : null
        data.username = states.user.username
        data.reset = reset
        return await AX.post(states.URL+'/users/init/tfa', data)
        .then((res) => {
            states.user = res.data.user;
            console.log('KYC request Response', res)
            return {status: 1};
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
            
        })
    }
    const registerClient = async ({commit}, data) => {
        return await axios.post(states.URL+'/users/register/client', data)
        .then((res) => {
            console.log('client registration response', res)
            return res;
        })    
    }
    const submitKyc = async ({commit}, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/users/submit/kyc', data)
        .then((res) => {
            console.log('KYC request Response', res)
            return res;
        }).catch((error) => {
        return HANDLE_ERROR(context,error)
        })    
    }
    
    const deleteUser = async ({commit}, data) => {
        return await axios.post(states.URL+'/users/delete', data)
        .then((res) => {
            console.log('user delete response', res)
            return res;
        })
    }
    const initKyc = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/users/kyc/init', {
            currentKyc:data.crKyc,
            userId:(states.user) ? states.user._id : null,user: data.user, type: data.type
        })
        .then((res) => {
            console.log('Kyc Init response', res)
            return res;
        })
        .catch((error) => {
            return HANDLE_ERROR(context,error)
        })
    }
    const nextKyc = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/users/kyc/init/next', {
            userId:(states.user) ? states.user._id : null,user: data.user, type: data.type, nextStatus:data.next, message:data.message
        })
        .then((res) => {
            console.log('Kyc Init response', res)
            return res;
        }).catch((error) => {
            return HANDLE_ERROR(context,error)
        })
    }
    const loadDefaultSettings = async (context, data) => {
        console.log(data)
        // return
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/load/default-settings', data)
        .then((res) => {
            console.log('response for load default settings', res)
            return res;
        })
        .catch((error) => { 
            return HANDLE_ERROR(context,error)
        })    

    }
    const approveKyc = async (context, data) => {
        console.log(data)
        // return
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/users/kyc/approve', data)
        .then((res) => {
            console.log('client KYC update response', res)
            return res;
        })
        .catch((error) => { 
            return HANDLE_ERROR(context,error)
        })    

    }
    const approveKycInit = async (context, data) => {
        console.log(data)
        // return
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/users/kyc/approve/init', data)
        .then((res) => {
            console.log('client KYC update response', res)
            return res;
        })
        .catch((error) => { 
            return HANDLE_ERROR(context,error)
        })    

    }
    const updateUserKyc = async (context, data) => {
        console.log(data)
        // return
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/users/kyc/update-status', data)
        .then((res) => {
            console.log('client KYC update response', res)
            return res;
        })
        .catch((error) => { 
            return HANDLE_ERROR(context,error)
        })    
        
    }
    const rejectKyc = async (context, data) => {
        console.log(data)
        // return
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/users/kyc/reject', data)
        .then((res) => {
            console.log('client KYC update response', res)
            return res;
        })
        .catch((error) => { 
            return HANDLE_ERROR(context,error)
        })    

    }
    const checkUserKyc = async (context) => {
        return await AX.post(states.URL+'/users/check/kyc/'+(states.user) ? states.user._id : null,{userId : (states.user) ? states.user._id : null})
        .then((res) => {
            console.log('kyc state check for user response', res)
            if(res.data.status){
                states.user = res.data.user
                return true
            }else{
                return false
            }
        })
        .catch((error) => {
            return HANDLE_ERROR(context,error)
        }) 
    }
    const getKycPdf = async ({commit}, data) => {
       return await axios.get(states.URL+'/users/kyc/pdf/'+data.kyc)
      .then(response => {
          console.log('response of the get pdf in store',response)
          return response.status;
      })
      .catch((error) => {
        return HANDLE_ERROR(context,error)
      })
        // window.open(url, )
    }
    const readPdf = async ({commit}, data) => {
        let url = states.URL+'/users/kyc/get/pdf/'+data.kyc;
        window.open(url,'_blank')
    }
    const checkKycStatus = async ({commit}, data) => {
        return await axios.post(states.URL+'/users/kyc/check', data)
        .then((res) => {
            console.log('kyc check status', res)
            return res.data;
        })
        .catch((error) => { 
        return HANDLE_ERROR(context,error)
         })
    }
    const requestTfaReset = async (context, user) => {
        return await axios.get(states.URL+'/users/tfa/reset/request/'+user)
        .then((res) => {
            return res.data.status
        })
        .catch((error) => {
            return HANDLE_ERROR(context,error)

        })
    }
    const getSearchedUser = async (context, data) => {
        console.log('username for get/user/username is', data.username)
            axios.get(states.URL+'/users/get/user/'+data.username)
            .then((res) => {
                states.searchedUser = res.data.user
            })
    }
    const cancelOrder = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/orders/cancel-order',data)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
            
        }) 
    }
    const cancelUserOrder = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/orders/cancel-user-order',data)
        .then((res) => {
            console.log("cancel-user-order", res.data)
            return res.data;
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
            
        }) 
    }
    
    const updateMerchant = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/update/merchant',data)
        .then(async (res) => {
            console.log("response of action addpromoter", res.data)
            if (res.data.status) {
            return {status:1}
            }else{
            return {status:0}
            }
        })
        .catch((error) => {
            return HANDLE_ERROR(context,error)

        }) 
    }
    const updatePromoter = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/update/promotr',data)
        .then(async (res) => {
            console.log("response of action addpromoter", res.data)
            if (res.data.status) {
            context.state.promoters = res.data.promoters;
            return {status:1}
            }else{
            return {status:0}
            }
        })
        .catch((error) => {
            return HANDLE_ERROR(context,error)

        }) 
    }
    const addPromoter = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/add/promotr',data)
        .then(async (res) => {
            console.log("response of action addpromoter", res.data)
            if (res.data.status) {
            context.state.promoters = res.data.promoters;
            return {status:1}
            }else{
            return {status:0}
            }
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
            
        }) 
    }
    
    const getGoldOrders = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/get/gold-orders',data)
        .then(async (res) => {
            console.log(res)
            if (res.data.status) {
                console.log(res.data.orders)
            context.state.goldOrders = res.data.data;
            context.state.withdrawls = res.data.withdrawls;
            context.state.goldBars = res.data.bars;
            return {status:1}
            }else{
            return {status:0}
            }
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
            
        }) 
    }
    const getVaultAddresses = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/get/vault-addresses',data)
        .then(async (res) => {
            console.log(res)
            if (res.data.status) {
                // console.log(res.data.orders)
            context.state.vaultAddresses = res.data.data;
            return {status:1}
            }else{
            return {status:0}
            }
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
            
        }) 
    }
    
    const updateVaultAddress = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/update/vault-address',data)
        .then(async (res) => {
            console.log("response of action update/vault-address", res.data)
            if (res.data.status) {
            context.state.vaultAddresses = res.data.data;
            return {status:1}
            }else{
            return {status:0}
            }
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
            
        }) 
    }
    const deleteVaultAddress = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/delete/vault-address',data)
        .then(async (res) => {
            console.log("response of action delete/vault-address", res.data)
            if (res.data.status) {
            context.state.vaultAddresses = res.data.data;
            return {status:1}
            }else{
            return {status:0}
            }
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
            
        }) 
    }
    const addVaultAddress = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/create/vault-address',data)
        .then(async (res) => {
            console.log("response of action create/vault-address", res.data)
            if (res.data.status) {
            context.state.vaultAddresses = res.data.data;
            return {status:1}
            }else{
            return {status:0}
            }
        })
        .catch((error) => {
            return HANDLE_ERROR(context,error)

        }) 
    }
    const createGoldOrder = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        
        return await AX.post(states.URL+'/admin/create/gold-order',data)
        .then(async (res) => {
            console.log("response of action create/gold-order", res.data)
            if (res.data.status) {
            context.state.goldOrders = res.data.data;
            console.log("data in create gold order", data)
            await context.dispatch("addActivity",{activity: [{metric:'totalGoldInVault',value:data.order.orderData.totalGoldg,symbol:'g'}] })
            return {status:1}
            }else{
            return {status:0}
            }
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
            
        }) 
    }
    const updateOrderDeposit = async (context, data) => {
        // data.userId = (states.user) ? states.user._id : null
        
        // return await AX.post(states.URL+'/admin/update/gold-order',data)
        // .then(async (res) => {
        //     if (res.data.status) {
        //     return {status:1}
        //     }else{
        //     return {status:0}
        //     }
        // })
        // .catch((error) => {
        // return HANDLE_ERROR(context,error)
            
        // }) 
    }
    const updateOrderWithdraw = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        
        return await AX.post(states.URL+'/admin/update/gold-withdraw-order',data)
        .then(async (res) => {
            if (res.data.status) {
            return {status:1}
            }else{
            return {status:0}
            }
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
            
        }) 
    }
    
    const uploadPDFOrder = async (context, form) => {
        return await axios.post(states.URL+'/users/update/order-pdf/fgfgfgfg',form)
        .then((res) => { 
            console.log("res in KYC.js",res)
            return res.data.name
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
            
        })
    }
    const getPayments = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/get/payments',data)
        .then((res) => {
            context.state.payments = res.data.buy;
            context.state.paymentsSell = res.data.sell;
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
            
        }) 
    }
    const activateMerchant = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/activate/merchant',data)
        .then((res) => {
            return res.data.status;
        })
        .catch((error) => {
            return HANDLE_ERROR(context,error)

        }) 
    }    
    const getPromoters = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/get/promotrs',data)
        .then((res) => {
            context.state.promoters = res.data.data;
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
            
        }) 
    }
    const updateBarStatus = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/update/bars',data)
        .then((res) => {
            return {status:res.data.status}
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
        }) 
    }
    const updateGoldOrder = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/update/gold-order',data)
        .then((res) => {
            return {status:res.data.status}
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
        }) 
    }
    const createToken = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/users/create/api-token',data)
        .then((res) => {
            console.log("response of creaet token", res.data)
            return res.data
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
        }) 
    }
    const getAccessTokens = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/get/tokens',data)
        .then((res) => {
            context.state.tokens = res.data.tokens
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
        }) 
    }
    const removeToken = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/remove/token',data)
        .then((res) => {
            return res.data
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
        }) 
    }
    const getRolePermissions = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/get/role-permissions',data)
        .then((res) => {
            context.state.userRoles = res.data.roles
            context.state.userPermissions = res.data.permissions
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
        }) 
    }
    const createPermissions = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/create/user-permission',data)
        .then((res) => {
            context.dispatch("getRolePermissions", {})
            return res.data
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
        }) 
    }
    const createRole = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/create/user-role',data)
        .then((res) => {
            context.dispatch("getRolePermissions", {})
            return res.data
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
        }) 
    }
    const updateRolePerms = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/update/user-role-permissions',data)
        .then((res) => {
            context.dispatch("getRolePermissions", {})
            return res.data
        })
        .catch((error) => {
        return HANDLE_ERROR(context,error)
        }) 
    }
    
    
    
    
    
    
export {
    approveKycInit,updateRolePerms,getRolePermissions,createPermissions,createRole,removeToken,getAccessTokens,createToken,updateBarStatus,updateOrderWithdraw,updateOrderDeposit,updateVaultAddress,deleteVaultAddress,updateGoldOrder,getVaultAddresses,addVaultAddress,getGoldOrders,createGoldOrder,uploadPDFOrder,activateMerchant,getPayments,updateMerchant,updatePromoter,addPromoter,getPromoters,cancelUserOrder,loadDefaultSettings,cancelOrder,updateUserKyc,getSearchedUser,readPdf,requestTfaReset,verifyTfa,setTFA,checkUserKyc,getKycPdf,nextKyc,initKyc,submitKyc,deleteUser,rejectKyc,approveKyc,checkKycStatus,getRegTypes, registerClient
}