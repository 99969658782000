import Web3 from 'web3';
import state from '../store/state' 
// import CT from '../store/contract'
// import BSC from '../store/contractBSC'
// console.log(CT)
import axios from 'axios'
var states = state;
const FUSEG_ADDRESS = states.ADDRESS
const FUSEG_ABI =states.ABI
async function getContractWallets(){
  // states.merchants = []
  // states.blacklisted = []
  // states.superadmins = []
  let DIFF = states.CURRENT_BLOCK - states.START_BLOCK 
  let INTERVALS = Math.ceil(DIFF / 5000)
  INTERVALS++
  let lastState = 0
  // console.log("current block", states.CURRENT_BLOCK, INTERVALS)
  // console.log('Start block', states.START_BLOCK, 'Currentblock', states.CURRENT_BLOCK,'DIFF', DIFF,'INTERVALS', INTERVALS)
  const web3 = new Web3(states.provider);
	const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);
  
  for (let index = 0; index < INTERVALS; index++) {
    lastState = (states.START_BLOCK + (5000 * index ))

let tb = (states.START_BLOCK+ (5000 * (index+1) ));
var START_BLOCK = lastState;
let ID = START_BLOCK.toString()+tb.toString();
// console.log('generated id is', ID)
let isFetched = await axios.post(states.URL+"/admin/is-event-fetched",{uid:ID}).then((res) => {return res.data.status } )
if (isFetched) {
  // console.log("log is already fetched", ID)
}
else{
  // console.log("log is Not already fetched",START_BLOCK, tb )
  var eventData = [];
  var eventData2 = [];
  var eventData3 = [];
  // console.log('START_BLOCK',START_BLOCK)
  await FUSEG_CONTRACT.getPastEvents("newMerchantEvent",
        {                               
  fromBlock: START_BLOCK,     
  toBlock: tb         
        })                              
  .then((events) => { eventData= events})
  .catch((err) => console.error('error in newMerchantEvent',err));
  await FUSEG_CONTRACT.getPastEvents("Blacklisted",
        {                               
  fromBlock: START_BLOCK,     
  toBlock: tb         
        })                              
  .then(events =>  eventData2= events)
  .catch((err) => console.error('error in Blacklisted',err));
   await FUSEG_CONTRACT.getPastEvents("newSuperAdminEvent",
        {                               
  fromBlock: START_BLOCK,     
  toBlock: tb         
        })                              
  .then(events =>  eventData3= events)
  .catch((err) => console.error('error newsupradminevent',err));
  console.log('merhcants',eventData,'blacklisted',eventData2,'admins',eventData3)
  let hasRecords = null
  if (eventData.length || eventData2.length || eventData3.length) {
    hasRecords = true
  }else{
    hasRecords = false
  }

  await axios.post(states.URL+"/admin/add-event-log/", {UID:ID, s: START_BLOCK, e: tb, hasRecords})
}
  
  }
  axios.get(states.URL+"/admin/get-last-log")
  .then(async (res) => { 
    console.log("last Log", res.data)
    var eventData = [];
    var eventData2 = [];
    var eventData3 = [];
    console.log('in Last Log START_BLOCK',res.data.event.start, "end block", res.data.event.end)
    await FUSEG_CONTRACT.getPastEvents("newMerchantEvent",
          {                               
    fromBlock: res.data.event.start,     
    toBlock: res.data.event.end         
          })                              
    .then((events) => { console.log('in Last Log START_BLOCK',res.data.event.start, "end block", res.data.event.end,"new merchants in last log", events)})
    .catch((err) => console.error('error in newMerchantEvent',err));
    await FUSEG_CONTRACT.getPastEvents("Blacklisted",
          {                               
    fromBlock: res.data.event.start,     
    toBlock: res.data.event.end         
          })                              
    .then(events =>  eventData2= events)
    .catch((err) => console.error('error in Blacklisted',err));
     await FUSEG_CONTRACT.getPastEvents("newSuperAdminEvent",
          {                               
    fromBlock: res.data.event.start,     
    toBlock: res.data.event.end         
          })                              
    .then(events =>  eventData3= events)
    .catch((err) => console.error('error newsupradminevent',err));
  let hasRecords = null  
  if (eventData.length || eventData2.length || eventData3.length) {
    hasRecords = true
  }else{
    hasRecords = false
  }

  await axios.post(states.URL+"/admin/add-event-log", {UID:res.data.event.UID, s:res.data.event.start, e: res.data.event.end, hasRecords})
  })
  
}

async function getContractWalletsBack(START_BLOCK, tb){
  return
  const web3 = new Web3(states.provider);
  const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);
  // console.log("log is Not already fetched", ID)
  var eventData = [];
  var eventData2 = [];
  var eventData3 = [];
  console.log('START_BLOCK',START_BLOCK)
  await FUSEG_CONTRACT.getPastEvents("newMerchantEvent",{fromBlock: START_BLOCK,toBlock: tb}).then((events) => { console.log("new merchants", events); eventData= events})
  .catch((err) => console.error('error in newMerchantEvent',err));
  if (states.merchants.length)
  { if (eventData.length) states.merchants.concat(eventData); }
  else states.merchants = eventData
  await FUSEG_CONTRACT.getPastEvents("Blacklisted",{fromBlock: START_BLOCK,toBlock: tb}).then(events =>  eventData2= events)
  .catch((err) => console.error('error in Blacklisted',err));
  if (states.blacklisted.length)
  { if (eventData2.length) states.blacklisted.concat(eventData2); }
  else states.blacklisted = eventData2

   await FUSEG_CONTRACT.getPastEvents("newSuperAdminEvent",{fromBlock: START_BLOCK,toBlock: tb}).then(events =>  eventData3= events)
  .catch((err) => console.error('error newsupradminevent',err));
  if (states.superadmins.length)
  { if (eventData3.length) states.blacklisted.concat(eventData3); }
  else states.superadmins = eventData3

}
async function getPrice(provider, address){
	try {
	  // console.log('getFgBalance');
	  const web3 = new Web3(provider);
	  const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);
	  let resp = await FUSEG_CONTRACT.methods.getPrice(address).call();
	  return {success: true, data: resp};
	} catch(e) {
	  return {success: false, error:e}
	}
	  return {success: false, error:{}}

  }
async function getFgBalance(provider, address){
	try {
	  // console.log('getFgBalance');
	  const web3 = new Web3(provider);
    console.log("address for getFgBalance", address)
	  const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);
	  let resp = await FUSEG_CONTRACT.methods.balanceOf(address).call();
	  // console.log('Responce:', resp);
	  return {success: true, data: resp};
	} catch(e) {
	  return {success: false, error:e}
	}
  }
async function getRefBalance(data){
  try {
    // console.log('Calling getBTCPrice');
    const web3 = new Web3(data.provider);
    const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);
    let resp = await FUSEG_CONTRACT.methods.getRefBalance(data.user).call();

    // console.log('Responce:', resp);
    return {success: true, data: resp};
  } catch(e) {
    return {success: false, error:e}
  }
}
async function getVar(provider, func){
  try {
    const web3 = new Web3(provider);
    const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);
    // let resp = null
    // if (func == "feeAddress") {
    let  resp = await FUSEG_CONTRACT.methods[func]().call();  
    // } else {
      
    // }
    
    // console.log("response:", func, resp )
    return {success: true, data: resp};
  } catch(e) {
    console.log("Error: ", func, e)
    return {success: false, error:e}
  }
}
// async function FOUR_ROLE(provider){
//   try {
//     const web3 = new Web3(provider);
//     const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);
//     let resp = await FUSEG_CONTRACT.methods.FOUR_ROLE(data.user).call();
//     return {success: true, data: resp};
//   } catch(e) {
//     return {success: false, error:e}
//   }
// }
// async function THREE_ROLE(provider){
//   try {
//     const web3 = new Web3(provider);
//     const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);
//     let resp = await FUSEG_CONTRACT.methods.THREE_ROLE(data.user).call();
//     return {success: true, data: resp};
//   } catch(e) {
//     return {success: false, error:e}
//   }
// }
// async function feeAddress(provider){
//   try {
//     const web3 = new Web3(provider);
//     const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);
//     let resp = await FUSEG_CONTRACT.methods.feeAddress(data.user).call();
//     return {success: true, data: resp};
//   } catch(e) {
//     return {success: false, error:e}
//   }
// }
const isMerchant = async (provider, address) => {
    const web3 = new Web3(provider);
    const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);
    try {
    let resp = await FUSEG_CONTRACT.methods.isMerchant(address).call();
    return resp;
      
    } catch (error) {
      console.log("errir in IsMerchant", error)
      return false
    }
}



async function getTotalSupply(provider){
  try {
    console.log('Calling getTotalSupply');
    const web3 = new Web3(provider);
    const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);
    // const hxyg_contract = await new web3.eth.Contract(hxyg_contract_compiled.abi, config.HXYG.CONTRACT_ADDRESS);
    let resp = await FUSEG_CONTRACT.methods.totalSupply().call();
    // console.log('Responce:', resp);
    return {success: true, data: resp};
  } catch(e) {
    return {success: false, error:e}
  }
}

async function mintTokens(data){
  let result = {}
  const web3 = new Web3(data.provider);
  const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);

  try {
    const resp = await FUSEG_CONTRACT.methods.mintTokens(
      data.account,
      web3.utils.toWei(data.amount.toString())
       ).send({
        from: data.from,
        gas: '900000'
      });

    // console.log('Responce:', resp);
    result = {success: true, data: resp,activity:[
      
    ]};
  } catch (e) {
    console.log("error in Mint Tokens", e)
    console.log("error in Mint Tokens", e.code)
    result = {success: false, error:e}
  }
  return result
}
async function BuyGold(data){
  let result = {};
  // console.log('calling buyGold', data.provider)
  try {
    const web3 = new Web3(data.provider);
    const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);
    const accounts = await web3.eth.getAccounts();
    const resp = await FUSEG_CONTRACT.methods.buyTokens(
      data.clientAddress,
      web3.utils.toWei(data.clientAmount.toString())
      ,
      web3.utils.toWei(data.feeAmount.toString())
      ,
      data.refAddress,
      ((data.refAmount*10**18).toFixed(0)).toString()
       ).send({
        from: accounts[0],
        gas: '900000'
      })
     console.log("resp of transaction", resp)
    return {success: true, data: resp, activity:[
      {metric:'totalBought',value:data.clientAmount,symbol:'FUSEG',client: data.clientAddress},
      {metric:'totalFeesEarned',value:data.feeAmount,symbol:'FUSEG'},
      {metric:'totalReferrals',value:data.refAmount,symbol:'FUSEG', client: data.refAddress},
    ]};
  } catch(e) {
    console.log("error in buy gold", e)
    console.log("error in buy gold", e.code)
    result = {success: false, error:e}
  }
  return result;
}

async function transferFrom(data){
	// console.log("we are in transferFrom:", data)
  let result = {};
  try {
    // console.log('Calling withdrawGold', data);
    const web3 = new Web3(data.provider);
    const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);
    const accounts = await web3.eth.getAccounts();
    let amt = data.goldAmt;
    const resp = await FUSEG_CONTRACT.methods.sellTokens(
		data.sender,
		web3.utils.toWei(data.amount.toString()),
		web3.utils.toWei(data.feeAmount.toString()),
		 ).send({
      from: accounts[0],
      gas: '900000'
    })
   

    // console.log('Responce:', resp);
    return {success: true, data: resp,
      activity:[
        {metric:'totalSold',value:data.amount,symbol:'FUSEG', client: data.sender},
        {metric:'totalFeesEarned',value:data.feeAmount,symbol:'FUSEG'},
      ]
    };
  } catch(e) {
    result = {success: false, error:e}
  }
  return result;
}

async function BurnGold(data){
  let result = {};
  try {
    // console.log('Calling withdrawGold', data);
    const web3 = new Web3(data.provider);
    const FUSEG_CONTRACT = await new web3.eth.Contract(FUSEG_ABI, FUSEG_ADDRESS);
    const accounts = await web3.eth.getAccounts();
    let fee = (data.mint) ? 0 : web3.utils.toWei(data.feeAmount.toString())
    const resp = await FUSEG_CONTRACT.methods.withdrawGold(
		data.client,
		 web3.utils.toWei(data.amount.toString()),
		 fee,
		 ).send({
      from: accounts[0],
      gas: '900000'
    })
   

    // console.log('Responce:', resp);
    return {success: true, data: resp,
      activity:[
        {metric:'totalExchanged',value:data.amount,symbol:'FUSEG', client: data.client},
        {metric:'totalFeesEarned',value:(data.mint) ? 0 :data.feeAmount,symbol:'FUSEG'},
      ],
      withdraw:{to:data.client, tx:resp.transactionHash, value:data.amount, title:"Client Exchange",type:"Auto", status:"Completed"}    
    };
  } catch(e) {
    result = {success: false, error:e}
  }
  return result;
}



export default {
  getContractWallets,
  getContractWalletsBack,
  transferFrom,
  BurnGold,
  mintTokens,
  getFgBalance,
  BuyGold,
  getPrice,
  getTotalSupply,
  getRefBalance,
  isMerchant,
  getVar
}