import axios from 'axios'
import AX from 'axios'
const TK = localStorage.getItem('fusegToken')
AX.defaults.headers.common['Authorization'] = (TK) ? TK : 'eyJhbGciOiJIUzI1NiJ9.ZnVzZS5nb2xkLUFwcA.IWwdTWsFFJJj87KBFwodDfbKF2Hk9hqhn2C7JtFAwzM';
import state from '../state' 
import router from '../../router'
var states = state;
function HANDLE_ERROR(context,error, redirect = true){
    
    if (error.response) {
        if(error.response.status == 401) {
            (redirect) ? context.dispatch('logout',error.response.data.error) : ''
            // console.log("got four o one error", error.response.data)
            
        }
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
        console.log('The request was made but no response was received')
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    return 0;
    // console.log(error.config);
}

// test functions
const loadTestUser = async ({context}) => {
    return await axios.get(states.URL+'/users/load-tests')
            .then((res) => {return res.data.status})
    // await context.dispatch("getKycRecords")
}
    const getUserTypes = () => {
    axios.get(states.URL+'/users/types')
    .then((res) => {states.userTypes = res.data})
    }
    const updateUserPermission = async (context, user) => {
        return await AX.post(states.URL+'/users/update/priv', {user})
        .then((res) => { 
            if(res.data.status){
                states.allUsers = res.data.users;
                return {status: 1};
            }else{
                return {status: 0, message: res.data.message}
            }
        })
    }
    const updatePassword = async (context, data) => {
        return await axios.post(states.URL+'/users/update/password', data)
        .then((response) => {
            console.log(response)
            return response.data
        }).catch((error) => {return HANDLE_ERROR(context,error)
        }) 
    }
    const sendPasswordResetCode = async(context, username) => {
        return await axios.post(states.URL+'/users/send/code', {username})
        .then((response) => {
            return response.data
        }).catch((error) => {return HANDLE_ERROR(context,error)
        })
    }
    const addAdminUser = async (context, data) => {
        return await AX.post(states.URL+'/users/add', {
            username: data.username,
            first_name: data.first_name,
            last_name: data.last_name,
            password: data.password,
            email: data.email,
            BFA: data.BFA,
            SFA: data.SFA,
            userType: data.userType,
            verifs: data.verifs,
            perms: data.perms
        })
        .then((res) => {
            console.log(res)
            if(res.data.status){
                states.allUsers = res.data.users
                return res.data;
            }else{ return res.data}
        })
        .catch((error) => {return HANDLE_ERROR(context,error) })
    }
    const checkUserAuth = async (context) => {
        let user = localStorage.getItem('fusegUser');
        if(user)
        {
            try {
                user = JSON.parse(user);
                let us = await AX.post(states.URL+'/users/get-user', {user: user._id})
                .then((res) => { return res.data.user})
                .catch((error) => {
                    return HANDLE_ERROR(context,error)
                })
                console.log('user fetched from server', us)
                if(us){
                    states.userType = us.type;
                    states.user = us;
                }else{
                    states.user = null;
                    states.userType = null;
                }    
            } catch (error) {
                console.log("error in checking user", error);
                context.dispatch("logout", null)
                
            }
            
            
        }else{
            states.user = null;
            states.userType = null;            
        }
    }
    
    const getKycRecords = async (context) => {
        return await AX.post(states.URL+'/users/get/kyc-requests',{userId: (states.user) ? states.user._id : null}).then((response) => {
            // axios.get(states.URL+'/orders/check-order', {params:{address}}).then((response) => {
            states.kycReqs = response.data;
            return 1;
        }).catch(error => {
            return HANDLE_ERROR(context,error)
        });
    }
    const logout = async (context, data = null) => {
        // alert('somehow logout was triggered')
        if (states.user) {
            if(data == null){
                states.userType = null;
            states.user = null;
            localStorage.removeItem("fusegUser")
            localStorage.removeItem('fusegToken')
            router.push({path:"/"})
            }else{
                if (data == "timeout") {
                    // alert("timeout because of no activity")
              Toast.fire({ icon: 'error', title: "Logout due to no activity, please login again"})
              localStorage.removeItem("fusegUser")
            localStorage.removeItem('fusegToken')
              setTimeout(() => {
                router.push({path:"/"})}, 
                3000);
                }else 
                if (data = "unauthorized") {
                    localStorage.removeItem("fusegUser")
            localStorage.removeItem('fusegToken')
            router.push({path:"/"})
                }
            }    
        } else {
            states.userType = null;
            states.user = null;
            localStorage.removeItem("fusegUser")
            localStorage.removeItem('fusegToken')
            router.push({path:"/"})

        }
        
    }
    
    const getUserInfo = async(context, user) => {
        return await axios.get(states.URL+'/users/get/info/'+user)
        .then((res) => {
            console.log(res.data)
            return res.data.user
        })
    }
    
    const updateUser = async (context, data) => {
        console.log(data.perms, data.user)
        return await AX.post(states.URL+'/users/update', {user:data.user, perms:data.perms})
        .then((res) => { 
            if(res.data.status){
                states.allUsers = res.data.users;
                return {status: 1};
            }else{
                return {status: 0, message: res.data.message}
            }
        })
        .catch((error) => {
            return HANDLE_ERROR(context,error)

        })
    }
    const update2faStatus = async (context, data) => {
        console.log('update2faStatus');
        let status = (data) ? "Running" : "Active";
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/users/update/tfa-status', {user: states.user, status})
        .then((res) => { 
            if(res.data.status){
                states.user = res.data.user;
                return {status: 1};
            }else{
                return {status: 0}
            }
        })
        .catch((error) => {
            return HANDLE_ERROR(context,error)
        })

    }
    const update2faStatusAdmin = async (context, data) => {
        // console.log('update2faStatus');
        // let status = (data) ? "Running" : "Active";
        data.userId = (states.user) ? states.user._id : null

        return await AX.post(states.URL+'/admin/update/tfa-status', {user: data.user, status:data.status})
        .then((res) => { 
            if(res.data.status){
                // states.user = res.data.user;
                return {status: 1};
            }else{
                return {status: 0}
            }
        })
        .catch((error) => {
            return HANDLE_ERROR(context,error)
        })

    }
    const sendMail =async (context, data) => {
        return await axios.post(states.URL+'/users/send-mail', data)
        .then((res) => {
            if(res.data.status){
                return {status:1}
            }else{
                return {status:0}
            }
        })
    }
    const deleteUserAdmin = async (context, data) => {
        return await AX.post(states.URL+'/users/delete', data)
        .then((response) => {
            return response.data.status
        }).catch(error => {return HANDLE_ERROR(context,error)
        });
    }
    const lockUser = async (context, data) => {
        return await AX.post(states.URL+'/users/lock', data)
        .then((response) => {
            return response.data.status
        }).catch(error => {
            return HANDLE_ERROR(context,error)

        });
    } 
    const verifyTfaLogin = async (context, data) => {
        return await axios.post(states.URL+'/users/verify/tfa-login', data)
        .then((res) => {
            if(res.data.status == 1){
                localStorage.setItem('fusegUser', JSON.stringify(res.data.user))
                localStorage.setItem('fusegToken', res.data.token)
                // location.reload()
                return res.data;
            }
            console.log('2FA verify response ', res)
            return res.data;
        })
        .catch((error) => {
            return HANDLE_ERROR(context,error)
        })
    }
    const login = async (context, data) => {
        return await axios.post(states.URL+'/users/login', {username: data.username, password: data.password})
        .then((res) => { 
            console.log(res);
            // return 0;
            if(res.data.status){
                if(res.data.user.emailVerified){
                    if(res.data.user.twoFaStatus === "Running"){
                        return {status: 3, user: res.data.user};
                    }else{
                        states.userType = res.data.user.type;
                        states.user = res.data.user;
                        localStorage.setItem('fusegUser', JSON.stringify(res.data.user))
                        localStorage.setItem('fusegToken', res.data.token)
                        return {status: 1};
                    }
                    
                }
                else{
                    return {status: 2, user: res.data.user};
                }
                

            }else{
                return {status: 0, message: res.data.message}
            }
        })
    }
    const getBuyOrders = async function () {
        AX.post(states.URL+'/orders/check-order', {userId: (states.user) ? states.user._id : null}).then((response) => {
            // axios.get(states.URL+'/orders/check-order', {params:{address}}).then((response) => {
            console.log('get orders', response.data)
            states.buyOrders = response.data.one;
            states.buyOrdersStripe = response.data.two;
        }).catch(error => {
            return HANDLE_ERROR(context,error)
        });
    }
    
    // checkRef
    const checkRef = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null;
        return await AX.post(states.URL+'/admin/get-prom-info', data ).then((response) => {
        console.log('admin/get-prom-info', response)    
        return response.data
        }).catch(error => {return HANDLE_ERROR(context,error)
        });
    }
    const getPaymntInfo = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null;
        return await AX.post(states.URL+'/users/get-payment-info', data ).then((response) => {
        console.log('/set-payment-intent respose', response)    
        return response.data
        }).catch(error => {return HANDLE_ERROR(context,error)
        });
    }   
    const getPaymentData = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null;
        return await AX.post(states.URL+'/users/get-payment-data', data ).then((response) => {
        console.log('/set-payment-intent respose', response)    
        return response.data
        }).catch(error => {return HANDLE_ERROR(context,error)
        });
    }
    const InitPayIntent = async (context, data) => {
        // console.log('ri=unning InitPayIntent');
        data.userId = (states.user) ? states.user._id : null;
        return await AX.post(states.URL+'/users/set-payment-intent', data ).then((response) => {
        console.log('/set-payment-intent respose', response)    
        return response.data
        }).catch(error => {return HANDLE_ERROR(context,error)
        });
    }
    const getSellOrders = async function () {
        AX.post(states.URL+'/orders/get-sell-orders',{userId: (states.user) ? states.user._id : null} ).then((response) => {
            states.sellOrders = response.data;
        }).catch(error => {return HANDLE_ERROR(context,error)
        });
    }
    
    const getAllUsers = async (context) => {
        AX.post(states.URL+'/users',{userId: (states.user) ? states.user._id : null})
        .then((res) => {states.allUsers = res.data})
        .catch((error) => {
            return HANDLE_ERROR(context,error)
        })
    }
    const placeSellOrder = async (context, item) => {
        item.userId= states.user._id
        return await AX.post(states.URL+'/orders/create-sell-order', item)
        .then((res) => { console.log('res is',res); return res.data.status ? {status:1, message:"Success",order:res.data.data} : {status:0, message:"Failed to process"} })
        .catch((err) => { return HANDLE_ERROR(context,err)})
    }
    const createCW = async (context, item) => {
        item.userId= states.user._id
        return await AX.post(states.URL+'/admin/create/contract-wallet', item)
        .then((res) => { context.dispatch("getContractWalletsDB", {})  })
        .catch((err) => { return HANDLE_ERROR(context,err)})
    }
    const deleteCW = async (context, item) => {
        item.userId= states.user._id
        return await AX.post(states.URL+'/admin/delete/contract-wallet', item)
        .then((res) => { context.dispatch("getContractWalletsDB", {})  })
        .catch((err) => { return HANDLE_ERROR(context,err)})
    }
    const getContractWalletsDB = async (context, item) => {
        item.userId= states.user._id
        return await AX.post(states.URL+'/admin/get-contract-wallets', item)
        .then((res) => { 
            console.log("contract wallets", res.data) 
            context.state.superadmins = []
            context.state.merchants = []
            context.state.blacklisted = []

            let superadmins = []
            let merchants = []
            let blacklisted = []
            res.data.data.forEach(element => {
                if(element.type == "merchant") merchants.push(element)
                if(element.type == "superadmin") superadmins.push(element)
                if(element.type == "blacklisted") blacklisted.push(element)
            });
            context.state.superadmins = superadmins
            context.state.merchants = merchants
            context.state.blacklisted = blacklisted

        })
        .catch((err) => { return HANDLE_ERROR(context,err)})
    }
    const getClientOrdersHistory = async (context, id) => {
        let data = {
            id,
            userId:context.state.user._id
        }
        return await AX.post(states.URL+'/orders/get-client-orders', data)
        .then((res) => 
        { 
            // console.log('res of client orders is',res);
            if (res.data.status) {
                context.state.clientSellOrders = res.data.orders.sell;
                context.state.clientBuyOrders =  res.data.orders.buy;
            }else{
                context.state.clientSellOrders = [];
                context.state.clientBuyOrders = [];
            }
            //  return {status:res.data.status ? 1 : 0, order:res.data.status ? res.data.data : res.data.message } 
        })
        .catch((err) => { return HANDLE_ERROR(context,err)})
    }
    const getClientOrders = async (context, id) => {
        let data = {
            id,
            userId:id
        }
        return await AX.post(states.URL+'/orders/get-client-orders', data)
        .then((res) => 
        { 
            // console.log('res of client orders is',res);
            if (res.data.status) {
                context.state.clientSellOrders = res.data.orders.sell;
                context.state.clientBuyOrders =  res.data.orders.buy;
            }else{
                context.state.clientSellOrders = [];
                context.state.clientBuyOrders = [];
            }
            //  return {status:res.data.status ? 1 : 0, order:res.data.status ? res.data.data : res.data.message } 
        })
        .catch((err) => { return HANDLE_ERROR(context,err)})
    }
    const placeBuyOrder = async (context, item) => {
        item.userId = states.user._id
        return await AX.post(states.URL+'/orders/create-buy-order', item)
        .then((res) => 
        { 
            console.log('res is',res);
             return {aml:res.data.aml, status:res.data.status ? 1 : 0, order:res.data.status ? res.data.data : res.data.message } 
        })
        .catch((err) => {  return HANDLE_ERROR(context,err)})
    }
    
    
    const getSettings = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/get/config',data)
        .then((res) => { 
            console.log("loaded settings are:", res.data.data)
            states.settings = res.data.data
            states.RFP = res.data.data.RFP*100
            states.FP = res.data.data.FP*100
            states.SFP = res.data.data.SFP*100
            states.TW = res.data.data.TW
            states.TA = res.data.data.TA
            states.GoldTransferWallet = res.data.data.GoldTransferWallet
            return res.data.status
        } )
        .catch(function (error) {
           return HANDLE_ERROR(context,error,false)
          });
    }
    const getWhiteTokens = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/get/white-tokens',data)
        .then((res) => { context.state.whiteTokens = res.data.data; return {status:1}} )
        .catch((err) => { return HANDLE_ERROR(context,err)
        })
    }
    const getActivities = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/get/activities',data)
        .then((res) => { context.state.activities = res.data.data; return {status:1}} )
        .catch((err) => { return HANDLE_ERROR(context,err)
        })
    }
    const addWhiteToken = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/add/white-token',data)
        .then((res) => { context.state.whiteTokens = res.data.data; return {status:1} } )
        .catch((err) => { return HANDLE_ERROR(context,err)
        })
    }
    const addActivity = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/add/activity',data)
        .then((res) => { context.state.activities = res.data.data; return {status:1} } )
        .catch((err) => { return HANDLE_ERROR(context,err)
        })
    }
    const setMetrics = async (context) => {
        
        context.state.totalGoldInVault = 0;
                context.state.totalReferrals = 0;
                context.state.totalFeesEarned = 0;
                context.state.totalSentToClients = 0;
                context.state.totalBought = 0;
                context.state.totalSold = 0;
                context.state.totalExchanged = 0;
                context.state.activities.forEach(element => {
                    context.state[element.metric] += Number(element.value)
                });
    }
    
    const checkReferralID = async (context, data) => {
        return axios.post(states.URL+'/users/check-referral-id', {email:data.email})
        .then((res) => {
            console.log('checkInterest',res)
            return res.data
        })
        .catch((error) => {return HANDLE_ERROR(context,error)
        })

    }
    const checkRefe = async (context, data) => {
        return axios.post(states.URL+'/users/check-refe', {email:data.email})
        .then((res) => {
            console.log('checkInterest',res)
            return res.data
        })
        .catch((error) => {return HANDLE_ERROR(context,error)
        })

    }
    const checkReferral = async (context, data) => {
        return axios.post(states.URL+'/users/check-referral', {email:data.email})
        .then((res) => {
            console.log('checkInterest',res)
            return res.data
        })
        .catch((error) => {return HANDLE_ERROR(context,error)
        })

    }
    const addRegInterest = async (context, data) => {
        return axios.post(states.URL+'/users/add-prom-user', {user:data.user})
        .then((res) => {
            console.log('addRegInterest',res)
            return res.data
        })
        .catch((error) => {return HANDLE_ERROR(context,error)
        })

    }
    const addWithdraw = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        let url = (data.manual) ? '/admin/create/gold-withdraw-order' : '/admin/add/withdraw'
        return await AX.post(states.URL+url,data)
        .then(async (res) => { 
            // context.state.withdrawls = res.data.data; 
            if(data.manual) 
            {
                await context.dispatch("addActivity",{activity: [{metric:'totalExchanged',value:data.withdraw.orderData.totalGoldg,symbol:'g'}] })
                // context.state.goldBars = res.data.bars
            }
            return {status:1} } 
            )
        .catch((err) => { return HANDLE_ERROR(context,err)
        })
    }
    const deleteWhiteToken = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/delete/white-token',data)
        .then((res) => { context.state.whiteTokens = res.data.data; return {status:1} } )
        .catch((err) => { return HANDLE_ERROR(context,err)
        })
    }
    const updateWhiteToken = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/update/white-token',data)
        .then((res) => { context.state.whiteTokens = res.data.data; return {status:1} } )
        .catch((err) => { return HANDLE_ERROR(context,err)
        })
    }
    const updateSettings = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/update/config',data)
        .then((res) => { return res.data.status} )
        .catch((err) => { return HANDLE_ERROR(context,err)})
    }
    const verifyEmail = async (context, id) => {
        return await axios.get(states.URL+'/users/verify-email/'+id)
        .then((res) => {
            console.log('status of email verification', res.data)
            return res.data.status;
        })
        .catch((error) => {return HANDLE_ERROR(context,error)
        })
    }
    const sendQrCode = async (context, data) => {
        return await axios.post(states.URL+'/users/send-qrcode', data)
        .then((res) => {
            console.log('status of email qrcode', res.data)
            return res.data.status;
        })
        .catch((error) => {
            return HANDLE_ERROR(context,error)
        })
    }
    
    const verifyEmailProm = async (context, id) => {
        return await axios.get(states.URL+'/users/verify-email-prom/'+id)
        .then((res) => {
            console.log('status of email verification', res.data)
            return res.data.status;
        })
        .catch((error) => {return HANDLE_ERROR(context,error)})
    }
    const fetchNotifications = async (context, permissions) => {
        let data = {}
        states.notifications = [];
        console.log("Permissions are:", permissions)
        data.permissions = permissions;
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/fetch/notifications',data)
        .then((res) => { 
            states.notifications = []
            console.log('notifications fetched', res.data)
            if (permissions.super || permissions.admin) {
                res.data.notifications.forEach(element => {
                    if (element.for == "admin" || element.for == "client") {
                        states.notifications.push(element)
                    }
                });
            return res.data

            }else{
                states.notifications = res.data.notifications;
                states.notificationsG = res.data.general;
                return res.data
            }


        } )
        .catch((err) => {return HANDLE_ERROR(context,err)})
    }
    const updateNoti = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/admin/update/noti',data)
        .then((res) => { return res.data.status} )
        .catch((err) => { return HANDLE_ERROR(context,err) })
    }
    const addLog = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/users/add/log',data)
        .then((res) => { return res.data.status} )
        .catch((err) => {return HANDLE_ERROR(context,err)})
    }
    const updateNumber = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/users/update/number',data)
        .then((res) => { return res.data.status} )
        .catch((err) => {return HANDLE_ERROR(context,err)})
    }
    
    const activeMerchant = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/users/get/active-merchant',data)
        .then((res) => { context.state.AM = res.data.data} )
        .catch((err) => { return HANDLE_ERROR(context,err)})
    }
    const setBuyOrderProgress = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        console.log("data received in setBuyOrderProgress", data)
        // return
        return await AX.post(states.URL+'/orders/buy/update/progress',data)
        .then((res) => { return res.data.status} )
        .catch((err) => {return HANDLE_ERROR(context,err)})
    }
    const setSellOrderProgress = async (context, data) => {
        data.userId = (states.user) ? states.user._id : null
        return await AX.post(states.URL+'/orders/sell/update/progress',data)
        .then((res) => { return res.data.status} )
        .catch((err) => {return HANDLE_ERROR(context,error) })
    }
    const updateAvtar = async (context, form) => {
        return await axios.post(states.URL+'/users/update/avtar/'+states.user._id,form)
        .then((res) => { 
            states.user = res.data.user;
            return res.data.status
        } )
    }
    const notify = async (context, data) => {
        console.log("running notify function")
        await axios.post(states.URL+'/users/post/notify',data)
    }
    const notifyU = async (context, data) => {
        console.log("running notify function")
        await axios.post(states.URL+'/users/post/notifyu',data)
    }
    const getConter = async (context) => {
        axios.get(states.URL+"/users/get/counter")
        .then((res) => {
            console.log("response of order counter")
            context.state.orderCounter = res.data.counter
        })
    }
    const updateCounter = async (context) => {
        axios.get(states.URL+"/users/update/counter")
        .then((res) => {
            console.log("response of order counter")
            context.state.orderCounter = res.data.counter
        })
    }
    const fetchRegisterInterest = async (context, data) => {
        return await axios.get(states.URL+'/users/verifiy/interest/'+data.email)
        .then((res) => {return res.data})
        .catch((error) => { return HANDLE_ERROR(context,error)})
    }
    const getPromUsers = async (context) => {
        // states.promUsers = [];
            // states.refCount = {};
            // states.totalReferred = 0;
            // states.refCountVerified = {};
            // states.totalReferredVerified = 0;
        return await axios.get(states.URL+'/users/get/prom-users').then((response) => {
            // axios.get(states.URL+'/orders/check-order', {params:{address}}).then((response) => {
            states.promUsers = response.data.data;
            states.refCount = response.data.refCount;
            states.totalReferred = response.data.totalReferred;
            states.refCountVerified = response.data.refCountVerified;
            states.totalReferredVerified = response.data.totalReferredVerified;
            // refCount, totalReferred,refCountVerified,totalReferredVerified
            return 1;
        }).catch(error => {
            return HANDLE_ERROR(context,error)
        });
    }
    const getPromUsersPro = async (context) => {
     
        return await axios.get('https://skldev.fuse.gold/users/get/prom-users').then((response) => {
            states.promUsers = response.data.data;
            states.refCount = response.data.refCount;
            states.totalReferred = response.data.totalReferred;
            states.refCountVerified = response.data.refCountVerified;
            states.totalReferredVerified = response.data.totalReferredVerified;
            return 1;
        }).catch((error) => {
            return HANDLE_ERROR(context,error)
        });
    }
    const sendVerifEmail = async(context, data) => {
        return await axios.get(states.URL+'/users/send-prom-verification-email/'+data.email).then((res) => { return res.data})
    }
    const getNickName = async (context, data) => {
        return await axios.post(states.URL+'/users/get/nickname', data)
        .then((res) => {return res.data})
    }
    const updateUsernames = async (context, data) => {
        return await axios.post(states.URL+'/users/update-username', data)
        .then((res) => { console.log("response in store", res.data); return res.data})
    }
    const dltProm = async(context, data) =>{
        console.log("data in dltPron", data)
        data.userId =  states.user._id
        if (data.vr) {
            console.log("going for test")
            return await AX.post(states.URL+'/users/delete/prom/user/'+data.email)
            .then((response) => { return response.data})
            .catch((error) => {return HANDLE_ERROR(context,error)})            
        } else {
            console.log("going for pro")
            // return {status:0, message:"system error"}
        return await axios.get('https://skldev.fuse.gold/users/delete/prom/user/'+data.email)
        .then((response) => { return response.data})
        .catch((error) => {return HANDLE_ERROR(context,error)})    
        }
    }
    const migrateUser = async (context, user) => {
        return await axios.post(states.URL+'/users/migrate-user', {user})
        .then((res) => { return res.data})
        .catch((error) => { return HANDLE_ERROR(context,error)})
    }
    const updateRewards = async (context, data) => {
        data.userId =  states.user._id
        return await AX.post(states.URL+'/admin/update/rewards', data)
        .then((res) => { return res.data.status
        })
        .catch((error) => {return HANDLE_ERROR(context,error)})    
    }
    const addContractWallet = async (context, data) => {
        data.userId =  states.user._id
        return await AX.post(states.URL+'/admin/add/contract-wallet', data)
        .then((res) => { return res.data.status })
      }
      const getWemails = async (context, data) => {
        data.userId =  states.user._id
        return await AX.post(states.URL+'/admin/get/wemails', data)
        .then((res) => { context.state.wEmails = res.data.data })
      }
      const addEM = async (context, data) => {
        data.userId =  states.user._id
        return await AX.post(states.URL+'/admin/add/wemails', data)
        .then((res) => { 
            if (res.data.status) {
                context.state.wEmails = res.data.data 
                return res.data                
            } else {
                return res.data
            }
        })
      }
      const deleteEM = async (context, data) => {
        data.userId =  states.user._id
        return await AX.post(states.URL+'/admin/delete/wemail', data)
        .then((res) => { 
            console.log("response of deleteEM", res)
            if (res.data.status) {
                context.state.wEmails = res.data.data 
                return res.data                
            } else {
                return res.data
            }
        })
      }  
      const getRewardConfigLive = async (context) => {
        return await axios.get('https://fuseg-dev-app-u6wsh.ondigitalocean.app/admin/get/reward-config')
        .then((res) => { context.state.rewardConfig = res.data.data})
      }
      const removeContractWallet = async (context, data) => {
        data.userId =  states.user._id
        return await AX.post(states.URL+'/admin/remove/contract-wallet', data)
        .then((res) => { return res.data.status})
      }
      const getRewardConfig = async (context) => {
        return await axios.get(states.URL+'/admin/get/reward-config')
        .then((res) => { context.state.rewardConfig = res.data.data})
      }
      const clearData = () => {
        axios.get(states.URL+'/admin/clear/gold-mint-data')
      }
      const getPriceFeed = async(context) => {
        axios.get(context.state.URL+'/api/v1/get-price')
        .then((res) => {
            console.log("getPriceFeedr",res)
            context.state.BTCPriceChainlink = res.data.price.BTC.price;
            context.state.GoldPriceChainlink = res.data.price.Gold.price;
            context.state.BNB = res.data.price.BNB.price;
            context.state.ETH = res.data.price.ETH.price;
            context.state.LINK = res.data.price.LINK.price;
        })
      }    
    
    
    
    
export {
    getPriceFeed,getRewardConfigLive,addEM, deleteEM,getWemails,clearData,getRewardConfig,removeContractWallet,addContractWallet,updateRewards,checkRefe,dltProm,migrateUser,updateUsernames,sendQrCode,getNickName,sendVerifEmail,checkReferral,checkReferralID,fetchRegisterInterest,deleteCW,createCW,getContractWalletsDB,getPromUsers,getPromUsersPro,addRegInterest,setMetrics,addWithdraw,addActivity,getActivities,updateWhiteToken,deleteWhiteToken,getWhiteTokens,addWhiteToken,activeMerchant,checkRef,getPaymntInfo,getPaymentData,getUserInfo,setBuyOrderProgress,setSellOrderProgress,updateCounter,getConter,getClientOrdersHistory,getClientOrders,updateNumber,addLog,notify,notifyU,updateAvtar,update2faStatusAdmin,updateNoti,fetchNotifications,verifyEmailProm,verifyEmail,updateSettings,getSettings,loadTestUser,deleteUserAdmin,lockUser,verifyTfaLogin,update2faStatus,sendMail,updatePassword,sendPasswordResetCode,getKycRecords,InitPayIntent,getSellOrders,getBuyOrders,placeSellOrder,placeBuyOrder,getUserTypes, addAdminUser, getAllUsers, logout, login, checkUserAuth, updateUser, updateUserPermission
}