<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2743 1000" class="img-fluid rounded mx-auto d-block" width="2743" height="1000">
        <rect :width="width" :height="height" fill="#cccccc"></rect>
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="Calibri" font-size="26px" fill="#333333">FUSE.GOLD</text>   
    </svg>
  </div>
</template>

<script>
export default {
    props:['height','width']
}
</script>

<style>

</style>