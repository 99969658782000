import DATA from './cards'
import CT from './contract'
import BSC from './contractBSC'
import GOLDX from './contractGOLDX'
const NETWORK = 'GOLDX';
// Aggregator addresses
// BSC - BNB  0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526
// BSC - ETH  0x143db3CEEfbdfe5631aDD3E50f7614B6ba708BA7
// BSC - LINK 0x1B329402Cb1825C6F30A0d92aB9E2862BE47333f
// BSC - BTC  0x5741306c21795FdCBb9b265Ea0255F499DFe515C
// console.log(CT)
const ABI = (NETWORK == "GOLDX") ? GOLDX.abi : BSC.abi;
const ADDRESS = (NETWORK == "GOLDX") ? GOLDX.address : BSC.address;
// const ADDRESS = CT.address
// const START_BLOCK = 23688832
const START_BLOCK = 23752550 

const state = {
  URL:"https://devapp.fuse.gold",
  // URL:"http://localhost:3030",
  userRoles:[],
  wEmails:[],
  sidebar:false,
  baselineTaxRate:0,
  THREE_ROLE:null,
  threeRoleAddress:null,
  userPermissions:[],
  isLoading:false,
  totalGoldInVault:0,
  totalReferrals:0,
  unBlacklistedWallets:[],
  blacklistedWallets:[],
  prom:true,
  PAXG:0,
  rewardConfig:{},
  NETWORK,
  START_BLOCK,
  CURRENT_BLOCK: 0,
  END_BLOCK:START_BLOCK+5000,
  totalFeesEarned:0,
  routeLoader:false,
  totalSentToClients:0,
  totalBought:0,
  promUsers:[],
  totalSold:0,
  totalExchanged:0,
  withdrawls:[],
  GoldTransferWallet:null,
  searchedUser:null,
  ContractOwner:null,
  clientSellOrders:[],
  clientBuyOrders:[],
  orderBalance:0,
  ABI,
  goldOrders:[],
  goldBars:null,
  vaultAddresses:[],
  paymentSellSearch:'',
  treasuryWalletBalance:0,
  treasuryWallet:null,
  taxWallet:null,
  feeWallet:null,
  paymentsSell:[],
  paymentSearch:'',
  merchantSearch:'',
  AM:null,
  txConfirmCounter:300000,
  orderCounter:null,
  ADDRESS,
  web3:null,
  payments:null,
  PUP:false,
  promoters:[],
  pausedStatus:null,
  mainConfig:{},
  current_limit:null,
  taxWalletsTX:[],
  taxWallets:[],
  merchantsTX:[],
  merchants:[],
  superadminsTX:[],
  superadmins:[],
  blacklistedTX:[],
  blacklisted:[],
  regTypeAML:null,
  buyOrders:[],
  buyOrdersStripe:[],
  notifications:[],
  notificationsG:[],
  merchantTxTimer:10000,
  cCurrs:[],
  settings:{},
  regTypeId:null,
  burntTokensInWei:0,
  walletBallanceInWei: 3454.34564,
  kycReqs:[],
  estRate:"0.0000",
  sellOrders:[],
  aType:'success',
  aMessage:'Alert Message',
  aStatus: false,
  userType:null,
  user: null,
  activities:[],
  provider:null,
  userTypes:[],
  regTypes:[],
  regType:null,
  userWallet:null,
  allUsers:[],
  action:"signin",
  card: DATA.card1,
  card2: DATA.card2,
  records: DATA.records,
  recordsMarchant: DATA.recordsMarchant,
  marchantTrx: DATA.marchantTrx,
  wpCards: DATA.wpCards,
  scCards: DATA.scCards,
  scInfoCard: DATA.scInfoCard,
  scInfoCardsTwo: DATA.scInfoCardsTwo,
  //end custom states
  goldPrice: 0,
  btcPrice: 0,
  hxygPrice: 0,
  ethAddress: '',
  auditDate: 0,
  burnAmount: 0,
  userFgBalance:0,
  poolBalance: 0,
  hxygBalance: 0,
  totalDividends: 0,
  totalLQPoolValue: 0,
  totalSupply: 0,
  refCount:{},totalReferred:0,refCountVerified:{},totalReferredVerified:0,promUsers:[],
  dividends: 0,
  expTimer:36000000,
  whiteTokens:[],
  tokens:[],
  networkName:"GOLDX Mainnet",
  networkID:(NETWORK == "GOLDX") ? "0xa573" : '0x61',
  scanLink:(NETWORK == "GOLDX") ? "https://explorer.goldxchain.io/" : 'https://testnet.bscscan.com/',
  // BTCPriceChainlink:19077.11,
  // GoldPriceChainlink:59.15,
  BTCPriceChainlink:0,
  GoldPriceChainlink:0,
  refBalance:'',
  FP:200,
  TA:null,
  TW:"0x06cDf5fcd8c5cb3CcA913650E161296960a07D87",
  FW:"0x06cDf5fcd8c5cb3CcA913650E161296960a07D87",
  TxW:"0x06cDf5fcd8c5cb3CcA913650E161296960a07D87",

  SFP:200,
  RFP:200,
  BNB:0,
  LINK:0,
  ETH:0,
  dark: (localStorage.getItem('fuseGoldDarkMode') ) ? JSON.parse(localStorage.getItem('fuseGoldDarkMode')) : false
}

export default state;