import blockchainService from '../../services/blockchainService';
import axios from "axios";
import state from '../state' 
var states = state;
const url = states.URL;
const getContractVars = async (context) => {
  // let DEFAULT_ADMIN_ROLE = await blockchainService.getVar(context.state.provider,"DEFAULT_ADMIN_ROLE")
  // console.log("DEFAULT_ADMIN_ROLE", DEFAULT_ADMIN_ROLE)
  // let FOUR_ROLE = await blockchainService.getVar(context.state.provider,"FOUR_ROLE")
  // console.log("FOUR_ROLE", FOUR_ROLE)
  // let THREE_ROLE = await blockchainService.getVar(context.state.provider, "THREE_ROLE")
  // context.state.THREE_ROLE = THREE_ROLE
  // console.log("THREE_ROLE", THREE_ROLE)
  let feeAddress = await blockchainService.getVar(context.state.provider, "feeAddress")
  // console.log("feeAddress", feeAddress)
  context.state.feeWallet = feeAddress.data
  // let fourRoleAddress = await blockchainService.getVar(context.state.provider, "fourRoleAddress")
  // console.log("fourRoleAddress", fourRoleAddress)
  let getAdminLimit = await blockchainService.getVar(context.state.provider,"getAdminLimit")
  // console.log("getAdminLimit", getAdminLimit)
  context.state.current_limit = (getAdminLimit.success && getAdminLimit.data) ? (getAdminLimit.data / 1000000000000000000) : 0;
  let owner = await blockchainService.getVar(context.state.provider, "owner").then((res) => { console.log("ownerrrrrrrrrr", res); return res })
  context.state.ContractOwner = (owner.success) ? owner.data : null;
  let taxAddress = await blockchainService.getVar(context.state.provider, "taxAddress")
  // console.log("taxAddress", taxAddress)
  context.state.taxWallet = taxAddress.data

  // let taxList = await blockchainService.getVar(context.state.provider, "taxList")
  // let taxRate = await blockchainService.getVar(context.state.provider, "taxRate")
  // console.log("taxRate", taxRate)
  // let threeRoleAddress = await blockchainService.getVar(context.state.provider, "threeRoleAddress")
  // console.log("threeRoleAddress", threeRoleAddress)
  // context.state.threeRoleAddress = threeRoleAddress

  let treasuryAddress = await blockchainService.getVar(context.state.provider, "treasuryAddress")
  // console.log("treasuryAddress", treasuryAddress)
  context.state.treasuryWallet = treasuryAddress.data
  // console.log("owner is", context.state.ContractOwner, context.state.current_limit)

  // console.log(DEFAULT_ADMIN_ROLE, FOUR_ROLE, THREE_ROLE, feeAddress, fourRoleAddress, getAdminLimit, owner, taxAddress, taxList, taxRate, threeRoleAddress, treasuryAddress)
  
  // _totalValue
  // allowance
  // balanceOf
  // decimals
  
  
  // getRoleAdmin
  // getTotalBurnt
  // hasRole
  // isMerchant
  // isSpecificTax
  // name, 
  // owner,
  //  paused,
  // supportsInterface,
  //  symbol, 
  //  totalSupply, 
  //  verify_level
}
const getContractWalletsBack = async (context) => {
  // return
  axios.get(states.URL+'/admin/get-contract-wallets')
  .then((res) => {
  console.log("getContractWalletsBack", res.data)  
  context.state.merchants = (res.data.MA) ? res.data.MA : []
  context.state.blacklisted = (res.data.blacklisted) ? res.data.blacklisted : []
  context.state.superadmins = (res.data.superadmins) ? res.data.superadmins : []

  // context.state.merchantsTX = res.data.merchantsTX
  // context.state.blacklistedTX = res.data.blacklistedTX
  // context.state.superadminsTX = res.data.superadminsTX
  })
}

const getBNBPrice = async (context, data) => {
  if (context.state.whiteTokens.length) {
  } else {
    await context.dispatch('getWhiteTokens', {});
  }
  if (data.symbol == "FUSEG") {
    let oracle = context.state.whiteTokens.filter(item => (item.network == "BNB-Test" && item.symbol == "XAU"))
      let dec = await context.dispatch("getPrice", {provider:context.state.provider, contract:oracle[0].oracle})
      console.log("results of getGoldPrice", dec)
      if (dec) {
        context.state[data.var] = (dec/(1000000000000000000))*0.0321507466
      }
    }
  else{
    let ads = context.state.whiteTokens.filter(v => (v.symbol == data.symbol && v.network == "BNB-Test"))
    if (ads) {
      let dec = await context.dispatch("getPrice", {provider:context.state.provider, contract: ads[0].oracle})
      if (dec) {
        context.state[data.var] = dec / 10**ads[0].decimals
      }
    }
  }
    
}

const getPrice = async ({commit}, data) => {
    return await blockchainService.getPrice(data.provider, data.contract)
    .then((resp) => {
      return (resp.success)? resp.data : null
    });
}
const getContractWallets = async () => {
  blockchainService.getContractWallets()
}

const isMerchant = async ({commit}, data) => {
    return await blockchainService.isMerchant(data.provider, data.address).then((resp) => { return resp; })
}
const getFgBalance = ({commit}, data) => {
    // blockchainService.getTotalSupply(data.provider)
    // .then((resp) => {
    // })
    return new Promise((resolve, reject) => {
    blockchainService.getFgBalance(data.provider, data.address)
    .then((resp) => {
      if(resp.success) {
        resolve(resp.data);
      }
      else {
        console.log('Call failed get getFgBalance', resp.error);
        reject(resp.error);
      }
    });
  });
}

const getRefBalance = ({commit}, data) => {
  return new Promise((resolve, reject) => {
    blockchainService.getRefBalance(data)
    .then((resp) => {
      if(resp.success) {
        // console.log('getRefBalance', resp.data);
        commit('updateRefBalance', Number(resp.data/1000000000000000000).toFixed(2));
        resolve(resp.data);
      }
      else {
        console.log('Call failed', resp.error);
        reject(resp.error);
      }
    });
  });
}


const getClaimedDividends = ({commit}, provider) => {
  return new Promise((resolve, reject) => {
    blockchainService.getClaimedDividends(provider)
    .then((resp) => {
      if(resp.success) {
        console.log('Dividends claimed', resp.data);
        commit('updateTotalClaimedDividends', resp.data);
        resolve(resp.data);
      }
      else {
        console.log('Call failed', resp.error);
        reject(resp.error);
      }
    });
  });
}

const getTotalSupply = ({commit}, provider) => {
  return new Promise((resolve, reject) => {
    blockchainService.getTotalSupply(provider)
    .then((resp) => {
      if(resp.success) {
        console.log('Total supply retrived', resp.data);
        commit('updateTotalSupply', resp.data);
        resolve(resp.data);
      }
      else {
        console.log('Call failed', resp.error);
        reject(resp.error);
      }
    });
  });
}

const getLQPoolTotalValue = ({commit}, provider) => {
  return new Promise((resolve, reject) => {
    blockchainService.getLQPoolTotalValue(provider)
    .then((resp) => {
      if(resp.success) {
        console.log('getLQPoolTotalValue Retrived', resp.data);
        commit('updateLQPoolTotalValue', resp.data);
        resolve(resp.data);
      }
      else {
        console.log('Call failed', resp.error);
        reject(resp.error);
      }
    });
  });
}

const getLQPoolBalance = ({commit}, provider) => {
  return new Promise((resolve, reject) => {
    blockchainService.getLQPoolBalance(provider)
    .then((resp) => {
      if(resp.success) {
        console.log('retrived', resp.data);
        commit('updateLQPoolBalance', resp.data);
        resolve(resp.data);
      }
      else {
        console.log('Call failed', resp.error);
        reject(resp.error);
      }
    });
  });
}

const calculateDividends = ({commit}, provider) => {
  return new Promise((resolve, reject) => {
    blockchainService.calculateDividends(provider)
    .then((resp) => {
      if(resp.success) {
        console.log('etrived', resp.data);
        commit('updateDividends', resp.data);
        resolve(resp.data);
      }
      else {
        console.log('Call failed', resp.error);
        reject(resp.error);
      }
    });
  });
}

const getTotalBurnt = ({commit}, provider) => {
  return new Promise((resolve, reject) => {
    blockchainService.getTotalBurnt(provider)
    .then((resp) => {
      if(resp.success) {
        console.log('etrived', resp.data);
        commit('updateBurnAmount', resp.data);
        resolve(resp.data);
      }
      else {
        console.log('Call failed', resp.error);
        reject(resp.error);
      }
    });
  });
}

const claimDividends = ({commit}, provider) => {
  return new Promise((resolve, reject) => {
    blockchainService.claimDividends(provider)
    .then((resp) => {
      if(resp.success) {
        commit();
        console.log('etrived', resp.data);
        resolve(resp.data);
      }
      else {
        console.log('Call failed', resp.error);
        reject(resp.error);
      }
    });
  });
}

function updateOrder(owner,id, txhash,rfp, fp, mt, cl, bl) {
            let endpoint = `${url}/orders/update-order/${id}&${txhash}&${owner}&${rfp}&${fp}&${mt}&${cl}&${bl}`;
            axios.post(endpoint,)
            .then((response) => {
                console.log(response)
            }).catch((err) => {
                alert(err)
            });
        }

function updateSellOrder(owner,id, txhash, type, mt, cl, bl) {
            let endpoint = `${url}/orders/update-sell-order/${id}&${txhash}&${owner}&${type}&${mt}&${cl}&${bl}`;
            axios.post(endpoint,)
            .then((response) => {
                console.log(response)
            }).catch((err) => {
                alert(err)
            });
        }
function completeSellOrder(owner,id,type) {
          let endpoint = `${url}/orders/complete-sell-order/${id}&${owner}&${type}`;
          axios.post(endpoint,)
          .then((response) => {
              console.log(response)
          }).catch((err) => {
              alert(err)
          });
      }        

const upBuyOrder = (context, data) => {
  console.log("data recieved in upBuyOrder:", data)
  updateOrder(data.owner,data.orderid, data.tx);

}
const upSellOrder = (context, data) => {
  console.log("data in upSellOrder", data)
  completeSellOrder(data.owner,data.orderid, data.type);

}

const mintTokens = async ({commit, dispatch}, data) => {
  console.log("data provided in MintTokens functions", data)
    return await blockchainService.mintTokens(data)
    .then((resp) => {
      
    return resp
});
}
const getTeamAddress = async (context, data) => {
  return await blockchainService.getTeamAddress(context.state.provider)
  .then((resp) => {
    context.state.TA = resp.data
});
}
const getbalanceTW = async (context, data) =>{
  console.log("starting getbalanceTW")
  data.provider = context.state.provider
  data.address = context.state.treasuryWallet
  console.log("data sent for getbalanceTW", data)
  let balance = await context.dispatch("getFgBalance", data)
  console.log("result getbalanceTW", balance)
  context.state.treasuryWalletBalance = balance/10**18
}
const getClientBalance = async (context, data) =>{
  data.provider = context.state.provider
  data.address = context.state.userWallet
  let balance = await context.dispatch("getFgBalance", data)
  context.state.userFgBalance = balance/10**18
}
const transferFrom = ({commit, dispatch}, data) => {
  return new Promise((resolve, reject) => {
        blockchainService.transferFrom(data)
    .then((resp) => {
      if(resp.success) {
        dispatch('addActivity',{activity:resp.activity} )
        updateSellOrder(data.owner,data.orderid, resp.data.transactionHash, 'BANK',"totalSold", data.sender, resp.data.blockNumber);
        console.log('Retrived, Buy Gold', resp.data);
        resolve(resp.data);
      }
      else {
        console.log('Call failed', resp.error);
        reject(resp.error);
      }
    });
  });
}
const BuyGold = ({commit, dispatch }, data) => {
  return new Promise((resolve, reject) => {
   
        blockchainService.BuyGold(data)
    .then((resp) => {
      if(resp.success) {
        commit('updateBurnAmount', data.goldAmt);
        
        dispatch('addActivity',{activity:resp.activity} )
        updateOrder(data.owner,data.orderid, resp.data.transactionHash,data.RFP, data.FP,"totalBought", data.clientAddress, resp.data.blockNumber);
        console.log('Retrived, Buy Gold', resp.data);
        resolve(resp.data);
      }
      else {
        console.log('Call failed', resp.error);
        reject(resp.error);
      }
    
    })
    
  });
}

const BurnGold = ({commit, dispatch}, data) => {
  return new Promise((resolve, reject) => {
    console.log("data in BurnGold", data)
    
      blockchainService.BurnGold(data)
    .then((resp) => {
      if(resp.success) {
        if (!data.mint) {
          dispatch('addActivity',{activity:resp.activity} )
          dispatch('addWithdraw',{withdraw:resp.withdraw} )       
          updateSellOrder(data.owner,data.orderid, resp.data.transactionHash,data.type, "totalExchanged", data.client, resp.data.blockNumber );
        }
        commit('updateBurnAmount', data.goldAmt);
        console.log('Retrived, Burn Gold', resp.data);
        resolve(resp.data);
      }
      else {
        console.log('Call failed', resp.error);
        reject(resp.error);
      }
    });

      })
     
}

export {
  getContractVars,
  getContractWalletsBack,
  getContractWallets,
  mintTokens,
  isMerchant,
  getPrice,
  transferFrom,
  upBuyOrder,upSellOrder,
  BurnGold,
  getClientBalance,
  BuyGold,
  getBNBPrice,
  getbalanceTW,
  getFgBalance,
  getTeamAddress,
  getClaimedDividends,
  getTotalSupply,
  getLQPoolTotalValue,
  getLQPoolBalance,
  calculateDividends,
  claimDividends,
  getTotalBurnt,
  getRefBalance
}