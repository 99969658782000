import {updateTeamAddress,updateGoldPrice,updateRefBalance,updateFP,updateRFP,updateSFP} from './blockchain';
import { 
  updateHXYGBalance,
  updateTotalSupply,
  updateTotalClaimedDividends,
  updateLQPoolTotalValue,
  updateLQPoolBalance,
  updateDividends,
  updateETHAddress,
  updateBurnAmount
  } from './blockchain';

export default { 
  updateTeamAddress,
  updateRefBalance,
  updateGoldPrice,
  updateHXYGBalance,
  updateTotalSupply,
  updateTotalClaimedDividends,
  updateLQPoolTotalValue,
  updateLQPoolBalance,
  updateDividends,
  updateETHAddress,
  updateBurnAmount,
  updateFP,
  updateRFP,
  updateSFP
};