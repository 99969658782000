<template>
  <div id="app"  class='' :class='{"dark":$store.state.dark }' >
    <nav-bar :wallet='hasWallet'></nav-bar>
  
  <div v-if="$root.Brender" class="pdf-cont-usage" style="width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 99999999999;
    top: 1px;left:0px;">
    <button class="btn btn-secondary text-white" @click="$root.Brender = false" style="position: fixed;
    top: 33px;
    right: 5px;z-index:9999999999;">&#10006;</button>
      <!-- <vue-pdf-app page-scale="50" pdf="https://assets-hxy.fra1.digitaloceanspaces.com/fuseg/brc.pdf"></vue-pdf-app> -->
      <pdf-comp url="https://assets-hxy.fra1.digitaloceanspaces.com/fuseg/brc.pdf"></pdf-comp>

      </div>
      <div v-if="$root.render" class="pdf-cont-usage" style="width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 99999999999;
    top: 1px;left:0px;">
    <button class="btn btn-secondary text-white" @click="$root.render = false" style="position: fixed;
    top: 33px;
    right: 5px;z-index:9999999999;">&#10006;</button>
<pdf-comp url="https://assets-hxy.fra1.digitaloceanspaces.com/fuseg/wp.pdf"></pdf-comp>
      </div>

    
    <div class="row justify-content-end p-0 m-0" v-if='ready'>
      <div v-if="hasWallet">
        <sidebar-comp></sidebar-comp>
      </div>
        <div class="col-lg-12 p-0">
          <div v-if="$store.state.routeLoader"   class="position-fixed opOne">
<img src="@/assets/loader.gif" alt="" srcset="">
</div>
  <router-view v-else></router-view>
        </div>
      </div>
      <div v-else>
        <div class="position-fixed opOne">
<img src="@/assets/loader.gif" alt="" srcset="">
</div>
      </div>
      <register-modal></register-modal>
  </div>
</template>
<script>

export default {
  name: "App",
  components:{
    RegisterModal:() => import('@/components/RegisterModal.vue'),
    SidebarComp:() => import('@/views/SidebarComp.vue'),
    NavBar:() => import('@/components/NavBar.vue'),
    PdfComp:() => import('@/components/PdfComp.vue'),

  },
  computed:{
    hasWallet(){
      // console.log(this.$route)
      if (this.$route.meta.wallet) {
        return true
      }else{
        return false;
      }
    },
    
  },
  data() {
    return {
      hasSidebar:false,
      ready: false,
      fMessage:`This verification link has either expired or already been used.<br>
Please check if your email address is already verified by clicking the "Get My Referral Link" button and entering your email address.`
    }
  },
  async mounted() {
    // this.$store.state.routeLoader = true
    this.ready = false;
       await  this.loadApp();
       this.hasSidebar = false
      //  this.$store.state.sidebar = true
     },
      methods:{
        async loadApp() {
          this.ready = true;
    this.$store.state.routeLoader = false
        console.log('the current route it',this.$route,)
     setTimeout(async() => {
            if(this.$route.name == "Email"){
             console.log('we are in email verification route')
             let dec = null
       dec = await this.$store.dispatch('verifyEmailProm', this.$route.params.id)
              
      console.log('home emal verify', dec)
      let message = null
      if (dec) {
        if (dec == 2) message = this.fMessage 
        else if (dec == 1) message = "Email Address has been verified Successfully";
        }else{
          message = 'Unable to verify email'
        }
        const SL = this.$root.Swal.mixin({
        customClass: {  confirmButton: 'btn btn-lg btn-primary mr-3', denyButton: 'btn btn-lg btn-primary'},
        buttonsStyling: false
        })
            if (dec == 1) {
        this.$router.push({name:"verified"})
        } else {
        SL.fire({
        title: message,
        showDenyButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        confirmButtonText: 'Login',
        denyButtonText: `Close`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setTimeout(() => {
            console.log('proceeding to login')
            this.$store.state.action = "signin"
            this.$bvModal.show("modal-register")  
          }, 
          1000 );
        } 
      })
      }
      
    }else{
      console.log("this is home route, not a verification route")
    }
     }, 1000);
     setTimeout(() => {
      if (this.$store.state.user && this.$router.currentRoute.fullPath == "/") {
        this.$router.push({name:'ClientDashboard'})
        }else{
          console.log('unable to redirect to dashboard', this.$store.state.user, this.$router.currentRoute.fullPath)
        }
    }, 2000);
      },
      }
}
</script>

<style lang="scss">

</style>
