let DATA = {
    scInfoCardsTwo:[
        {
            img:1,
            title:"Professionally Vaulted and Insured",
            desc:`
            100% of all precious metals held in our vaults or safety deposit boxes are fully insured. There are no
hidden costs or surcharges as the premiums for your insurance are already included and covered by
the initial fee charged for tokenizing your gold bullion to FUSEG. This innovative dual method of
vaulting and tokenizing allows us to stay competitive with standard storage alone companies. This
allows our clients maximum flexibility with the added benefits of Cryptocurrency and physical gold
combined.`,
        },
        {
            img:2,
            title:"PREFERENTIAL BUY BACK RATES ",
            desc:`
            When you decide to convert your FUSEG tokens back to physical bullion we will buy back the gold
at 99% of the spot price. This will be minus the minimal fee associated with the administration of
the FUSEG tokens, thus allowing us to remain competitive.`,
        },
        {
            img:3,
            title:"100% Allocated and Individually Segregated",
            desc:`
            Our vaulting service is individually segregated and fully allocated so there is minimal counterparty
risk. All bullion is held in the name of the account holder. Our vaults are also independently audited
Tri-Annually. Our integrated Proof of Reserve (PoR) solution also offers total transparency via 24/7
real-time blockchain verifiable auditing. This means at any moment in time, anyone can verify the
physical gold backing the circulating supply of FUSEG.`

        },
        {
            img:4,
            title:"Physical Delivery Available on Request",
            desc:`For amounts above a specified minimum threshold (typically 1 Troy Ounce) we can arrange delivery
            of all or part of your precious metal holdings. All deliveries will be fully insured up to £50,000 per
            shipment. For higher value or international shipments we can deliver split shipments or alternatively
            via a high security armoured vehicle. (Additional charges will be incurred for this method of
            transport)`,
        },
        {
            img:6,
            title:"Terms and Conditions",
            desc:`Please click here to view the Sharps Pixley custody terms and conditions.`
        },
        
    ],
    scInfoCard:{
        scCardOne:{
            title:"GOLD",
            desc:`Gold custody and insurance charge: Holdings below £100,000 are charged a flat fee of £25 per month. Holdings above £100,000 are charged at 0.30% per annum. Fees shown include comprehensive insurance, irrespective of commodity price fluctuations, and are inclusive of VAT.`,
        },
        scCardTwo:{
            title:"SILVER",
            desc:`Silver custody and insurance charge: Holdings below £100,000 are charged a flat fee of £50 per month. Holdings above £100,000 are charged at 0.6% per annum. Fees shown include comprehensive insurance, irrespective of commodity price fluctuations, and are inclusive of VAT.`,
        }
    },
    scCards:[
        {
            img: 1,
            title: "HIGH SECURITY VAULTS ",
            desc:`
            We offer all of our clients a complimentary maximum security vaulting solution for all precious metal
holdings. Our completely secure and fully audited reputable vaults provide the safest and most
reliable solution to storing your bullion. All precious metals are 100% personally allocated to each
individual holder at all times.
            `,
        },
        {
            img: 2,
            title: "INSURANCE",
            desc: `100% of all precious metals held in our vaults or safety deposit boxes are fully insured by (“) There are no hidden costs or surcharges as the premiums for your insurance are already included and covered by the initial fee charged for tokenizing your gold bullion to FUSE G. This innovative dual method of vaulting and tokenizing allows us to stay competitive with standard storage alone companies. This allows our clients maximum flexibility with the added benefits of crypto currency and physical gold combined.`,
        },
        {
            img: 3,
            title:"PEACE OF MIND",
            desc:`
            Our vaults and safety deposit boxes are fully audited and covered by 24 hour CCTV surveillance,
comprehensive security processes and on-site close protection security officers. Our security systems
are directly linked to the Metropolitan Police at all times which offers the highest level of security
and peace of mind. In addition to a traditional, physical Tri-Annual audit, the Proof of Reserve (PoR)
solution offers total transparency via 24/7 real-time blockchain verifiable auditing. This means at any
moment in time, anyone can verify the physical gold backing the circulating supply of FUSEG.`,
        }
    ],
    wpCards:[
        {
            title: "World Class Referral System",
            desc:`
            Here at fuse.gold we have an outstanding single level referral program with FUSEG where anyone can refer new customers and earn free gold. There is no limit as to how much free gold can be earned and the percentage of the referral amount can be adjusted to suit market conditions.
<br>
Additionally gold X is our single layer referral system where anyone who refers new customers can earn 10% more gold X during the sign-up phase. Both the referrer and referee earn 10% more gold X. The gold X referral system also pays the referrer a percentage of future gold X transactions. These referral gold X amounts are catered for from the inbuilt 10% transaction tax that is applied to each gold X transaction. 
<br>

It is also worth noting that the free claim period of gold X automatically transfers any existing referrals over to the FUSEG referral program. Meaning any successful sign-ups which convert to FUSEG buys gain the referrer an allocation of FUSEG as well as gold X.
`,
            img: 1,
        },
        {
            title: "Allocated",
            desc: `
            fuse.gold is backed by physical gold and is assigned to the serial number of each individual bar. As fuse.gold utilises the Ethereum/Crypto blockchains, it is immediately transferable world-wide. The assigned ownership goes alongside the transfer of the token, which means trading allocated gold is simple and fast.
            `,            
            img: 2,

        },
        {
            title: "Redeemable",
            desc: `
            fuse.gold is redeemable in several forms, unlike any gold products currently available. Clients can convert their fuse.gold into physically allocated gold, other supported Cryptocurrencies and FIAT  via both decentralised and centralised exchanges. The largest pair being a  USD stable token/FUSEG. Using the fuse.gold platform, customers will be able to reclaim full-sized London Good Delivery Gold bars. FUSEG tokens can be exchanged for physical gold which can be delivered to you fully insured in shipments insured up to £50,000. (Multiple or bespoke shipments can be arranged over this amount), customers will be able to reclaim full-sized London Good Delivery Gold bars. `,
            img: 3,
        },
        {
            title:"Programmable",
            desc:`
            Due to the programmable and digital nature of smart contracts, FUSEG is easily converted and
traded. Digital gold creates important, innovative versatility in the Cryptocurrency space.`,
            img: 4,
        },
        {
            title: "Regulations",
            desc: `fuse.gold is issued by Fuse.Gold Limited, a financial institution regulated by the Gibraltar Financial
            Services Commission (GFSC).<br>
            The GFSC also regulates fuse.gold individual products, ensuring the highest customer protection
            and optimal operating standards. The GFSC has approved FUSEG as an authorised and unregulated
            fuse.gold product.`,
            img: 5,
        },
        {
            title: "Available to Anyone",
            desc: `
            Due to the fact fuse.gold has a very low investment minimum (1 FUSEG), divided ownership of bars is now attainable to customers. Any individual or
                institution can buy FUSEG with FIAT currencies (as long as it is legal to do so from their
                jurisdiction), BTC or other Cryptocurrencies.`,
            img: 6,
        },
        {
            title: "Low Fees",
            desc: `
            fuse.gold has low fees and therefore is instantly a more attractive investment opportunity compared
to existing gold products. There are no storage fees, the only fees payable stem from converting BTC,
FIAT currencies and other Cryptocurrencies to physical gold (1-2.5%)
`,
            img: 7,
        },
        {
            title: "Easy to Trade",
            desc: `
            Easily tradable on both decentralised and centralised exchanges, FUSEG will trade against digital
assets. Customers can convert their fuse.gold into physically allocated gold, other supported
Cryptocurrencies and FIAT via both decentralised and centralised exchanges. The largest pair
being a USD stable token/FUSEG.`,
            img: 8,
        },
        {
            title:"Free Physical Secure Vault Storage",
            desc:`
            Due to the nature of the way fuse.gold earns fees for tokenizing physical gold, it allows us to offset
the fees for the storage of the physical gold. Instead of charging our customers for the storage of
their physical gold we only charge for the tokenization; this means we can rival standard storage
facilities of solely physical gold whilst offering a brand new innovative digital solution.`,
            img: 9
        }

    ],
    marchantTrx:[
        {
            id:"#1202302",
            btc: "bc1qxjwfp8489qx26mwd549lwyjgk5k77cekmkgufm",
            name: "John Thomas Smith",
            score:5,
        },
        {
            id:"#1202303",
            btc: "bc1qxjwfp8489qx26mwd549lwyjgk5k77cekmkgufm",
            name: "Isabella Patricia Johnson",
            score:4,
        },
        {
            id:"#1202304",
            btc: "bc1qxjwfp8489qx26mwd549lwyjgk5k77cekmkgufm",
            name: "Isabella Patricia Johnson",
            score:3,
        },
        {
            id:"#1202305",
            btc: "bc1qxjwfp8489qx26mwd549lwyjgk5k77cekmkgufm",
            name: "Isabella Patricia Johnson",
            score:1,
        },
        {
            id:"#1202306",
            btc: "bc1qxjwfp8489qx26mwd549lwyjgk5k77cekmkgufm",
            name: "Isabella Patricia Johnson",
            score:2,
        },
        {
            id:"#1202302",
            btc: "bc1qxjwfp8489qx26mwd549lwyjgk5k77cekmkgufm",
            name: "John Thomas Smith",
            score:5,
        },
        {
            id:"#1202303",
            btc: "bc1qxjwfp8489qx26mwd549lwyjgk5k77cekmkgufm",
            name: "Isabella Patricia Johnson",
            score:4,
        },
        {
            id:"#1202304",
            btc: "bc1qxjwfp8489qx26mwd549lwyjgk5k77cekmkgufm",
            name: "Isabella Patricia Johnson",
            score:3,
        },
        {
            id:"#1202305",
            btc: "bc1qxjwfp8489qx26mwd549lwyjgk5k77cekmkgufm",
            name: "Isabella Patricia Johnson",
            score:1,
        },
        {
            id:"#1202306",
            btc: "bc1qxjwfp8489qx26mwd549lwyjgk5k77cekmkgufm",
            name: "Isabella Patricia Johnson",
            score:2,
        },
    ],
    recordsMarchant:[
        {
            date: '19/12/2021',
            amount: "3.22900011 FUSE G",
            target: "0.00 322 220 BTC",
            accepted: false
        },
        {
            date: '18/12/2021',
            amount: "0.23888221 FUSE G",
            target: "9.34 USD",
            accepted: false
        },
        {
            date: '18/12/2021',
            amount: "10.12211432 FUSE G",
            target: "0.01 011 000 BTC",
            accepted: false
        },
        {
            date: '19/12/2021',
            amount: "3.22900011 FUSE G",
            target: "0.00 322 220 BTC",
            accepted: true
        },
        {
            date: '18/12/2021',
            amount: "0.23888221 FUSE G",
            target: "9.34 USD",
            accepted: true
        },
        {
            date: '18/12/2021',
            amount: "10.12211432 FUSE G",
            target: "0.01 011 000 BTC",
            accepted: true
        },
        {
            date: '19/12/2021',
            amount: "3.22900011 FUSE G",
            target: "0.00 322 220 BTC",
            accepted: false
        },
        {
            date: '18/12/2021',
            amount: "0.23888221 FUSE G",
            target: "9.34 USD",
            accepted: false
        },
        {
            date: '18/12/2021',
            amount: "10.12211432 FUSE G",
            target: "0.01 011 000 BTC",
            accepted: false
        },
        {
            date: '19/12/2021',
            amount: "3.22900011 FUSE G",
            target: "0.00 322 220 BTC",
            accepted: true
        },
        {
            date: '18/12/2021',
            amount: "0.23888221 FUSE G",
            target: "9.34 USD",
            accepted: true
        },
        {
            date: '18/12/2021',
            amount: "10.12211432 FUSE G",
            target: "0.01 011 000 BTC",
            accepted: true
        },
    ],
    records:[
        { date: "21/12/2021", size: "1.1"},
        { date: "21/12/2021", size: "1.1"},
        { date: "21/12/2021", size: "1.1"},
        { date: "21/12/2021", size: "1.1"},
        { date: "21/12/2021", size: "1.1"},
        { date: "21/12/2021", size: "1.1"},
        { date: "21/12/2021", size: "1.1"},
        { date: "21/12/2021", size: "1.1"},
        { date: "21/12/2021", size: "1.1"},
        { date: "21/12/2021", size: "1.1"},
        { date: "21/12/2021", size: "1.1"},
        { date: "21/12/2021", size: "1.1"},
        { date: "21/12/2021", size: "1.1"},
        { date: "21/12/2021", size: "1.1"},
        { date: "21/12/2021", size: "1.1"},
    ],
    card1:{
        title:'WHY FUSE GOLD?',
        desc:[
        "The insurmountable power of gold has been felt in waves throughout societies all over the world for generations. This rare commodity has been prevalent since the dawn of time, and has come before all currencies as a form of desirable value in physical form. The magnitude and worth of this precious metal has only continued to increase in very quick time, which is why we underpin gold as the fundamental building block for all investments when it comes to longevity and growth."
        ],
        img: 1,
    },
    card2:{
        title:"TRANSFORM YOUR  PORTFOLIO",
        desc:[
            "Your Gold purchases become FUSE G Tokens, allowing you to diversify your portfolio with the various cryptocurrencies. You can keep adding multiple assets, switch between cryptocurrencies, convert it all back to gold or sell it all for cash whenever you choose. There are a magnitude of fully decentralised exchanges and centralised exchanges giving you around the clock freedom and flexibility.", 
            "It’s never been easier, quicker or more secure to convert your investments into assets of your choice in order to create a brilliant online portfolio with us. We provide 24/7 information and support to answer any questions or queries you may have. We believe in clarity, and that’s why we endeavour to ensure that our customers have a full understanding of their portfolio at all times."
        ],
        img: 2,

    },
}

export default DATA