<template>
  <div class="home" >
    <!-- <nav-bar  :wallet='false'></nav-bar> -->
    <div class="container-fluid p-0 d-lg-none" >
      <div class="row justify-content-center p-0 m-0">
        <div class="col-xl-10 col-md-12 px-3 px-md-5 m-0">
          <div class="mt-5">
              <h3 class="Mtt-one text-center up" > we fuse the security of gold<br> with the convenience of crypto </h3>
              <div class="mt-4">
<p class="Mdesc-one">
  Our mission is to combine the traditionally strong foundations of physical gold together with the modern digital benefits of exciting cryptocurrency assets, such as Bitcoin, Ethereum and much more. These assets can perform in unity and in tandem at your fingertips – ensuring you never miss an outstanding crypto opportunity with the capital of gold behind you.
         <button-shimmer></button-shimmer>
              </p>
              </div>
          </div>
          
        </div>
        <div class="col-12 m-0 p-0" >
            <img src="@/assets/images/Group-5.webp" alt="" height="auto" width="1080" class="mw-100p" srcset="" rel="preload">
        </div>
        <div class="col-12 p-0 mt-5">
          <div class="clLight d-inline-block  Mhtone px-3 px-md-5" >
            <div class="">
              <div class="" style="margin-top:10%;">
              <h3 class="Mtt-one text-center">WHY GOLDX & FUSE.GOLD?</h3>
              </div>
              <div class="mt-4">
              <p class="Mdesc-one-c justify-text">
The gold X token is the free reward token of the fuse.gold platform. As an application, fuse.gold allows users to buy and sell tokenized gold (FUSEG) and exchange it for any token on the Dex (subject to sufficient available liquidity). By Registering today, we will reward you with the free gold X token so  you can start earning gold as soon as the platform launches. This unique opportunity is free and limited in numbers. To be eligible for the free gold X token, please register today.
<button-shimmer></button-shimmer>
              </p>
            </div>
            </div>
          </div>
          <div class="d-block position-relative" >
            <img src="@/assets/images/Layer-11.webp" class="mw-100p d-block mx-auto" height="auto" width="749" alt="" 
            style="width:90%; margin-top: -11%;"
             srcset="">
          </div>
        </div>
        <div class="col-12 p-0 mt-5">
          <div class="clLight d-inline-block  Mhtone px-3 px-md-5" >
            <div class="">
              <div class="" style="margin-top:10%;">
              <h3 class="Mtt-one text-center">THE REAL <br>DIGITAL GOLD</h3>
              </div>
              <div class="mt-4">
              <p class="Mdesc-one-c justify-text">
                Love gold and want to be part of a new digital era? This is where fuse.gold comes into its own. All of our gold is backed and secured by verified 99.999% LBMA-approved gold stored in vaults around the United Kingdom. We are combining the stability of gold with  the technical advantages of Crypto and creating a new gold rush which will go down in history. Register today so we can alert you when we are fully live. Not only will you be one of the first to get a gold X token, but you will be one of the first with the ability to mine or just hold gold X, taking advantage of the early Proof of Volume (PoV) or Proof of Wait (PoW) advantages achieved via the fuse.gold and gold X tokenomics.

               <button-shimmer></button-shimmer>
              
              </p>
            </div>
            </div>
          </div>
          <div class="d-block position-relative" >
            <img src="@/assets/images/Layer-5.webp" class="mw-100p d-block mx-auto" height="auto" width="749" alt="" 
            style="width:90%; margin-top: -11%;margin-left: 10%;"
             srcset="">
          </div>
          <div class="position-relative" style="top: 7px;
    right: -41px;">
              <div class="Mswirel1 msone d-none d-md-block d-lg-none">
                      <img data-aos="fade-left"  class='swimg3'  src="@/assets/images/arrow-right.png" alt="">
                      <img data-aos="fade-left"  class='swimg4' src="@/assets/images/arrow-right.png" alt="">
                      
                      <img data-aos="fade-right" class='swimg1' src="@/assets/images/arrow-left.png" alt="">
                      <img data-aos="fade-right" class='swimg2' src="@/assets/images/arrow-left.png" alt="">
            </div>
            <div class="Mswirel1 mstwo d-block d-md-none">
                      <img data-aos="fade-left"  class='swimg3'  src="@/assets/images/arrow-right.png" alt="">
                      <img data-aos="fade-left"  class='swimg4' src="@/assets/images/arrow-right.png" alt="">
                      
                      <img data-aos="fade-right" class='swimg1' src="@/assets/images/arrow-left.png" alt="">
                      <img data-aos="fade-right" class='swimg2' src="@/assets/images/arrow-left.png" alt="">
            </div>
          </div>
          
        </div>
        <div class="col-12 p-0 Mmtt-15" >
          <div class="clLight d-inline-block Mhtone " style="padding-bottom: 1%;">
            <div class="px-3 px-md-5">
              <div class="" style="margin-top:10%;">
              <h3 class="Mtt-one text-center">TRANSFORM YOUR <br> PORTFOLIO</h3>
              </div>
              <div class="mt-4 mb-4">
              <p class="Mdesc-one-c justify-text mb-0">
              When building an investment portfolio, the one thing we all aim for is stability and growth. This new platform enables you to precisely do this. The strength of  gold-backed FUSEG  will ensure that your investment is safe and secure, whilst the free rewards token gold X will give your portfolio the maximum chance to grow.<br> <br>
 
The fuse.gold ecosystem  aims to become a household name in CryptoCurrency. To facilitate this, we have made the platform more accessible, quicker, and more secure to transform your investment into a portfolio you can be proud of. We provide 24/7 information and support to answer any questions or queries you may have. We believe in transparency and clarity, and that is why we endeavour to ensure that our customers always have a complete understanding of their portfolio.
</p>
              
            </div>

            </div>
            <div class=''>
               <button-shimmer></button-shimmer>
            </div>
          </div>
          <div class="d-block position-relative px-3 px-md-5" >
            <img src="@/assets/images/Layer-8.webp" class="mw-100p d-block mx-auto" height="auto" width="749" alt="" 
            
             srcset="">
          </div>
        </div>
        <div class="col-12 p-0 mt-5">
          <div class="clLight d-inline-block  Mhtone px-3 px-md-5" >
            <div class="">
              <div class="" style="margin-top:10%;">
              <h3 class="Mtt-one text-center">MAXIMUM SECURITY</h3>
              </div>
              <div class="mt-4">
              <p class="Mdesc-one-c justify-text">
Prior to launch, fuse.gold has undergone extensive legal due diligence to ensure that we have the capacity, the capability, and the proof of reserve to function as a legitimate and ethically performing company. Our gold is stored in highly reputable vaults of long standing across the United Kingdom. We are wholly  legally and legislatively compliant,  the safety of your assets is never in question. The moment you sign-up with fuse.gold is the moment your investments and assets are yours for life. Your physical gold is held safely in maximum-security vaults.  We have integrated a state of the art blockchain verifiable solution called Proof of Reserve (PoR).  In addition to a traditional, physical Tri-Annual audit, the PoR solution offers total transparency via a 24/7 real-time blockchain verifiable audit. This means at any moment in time, anyone can verify the physical gold backing the circulating supply of FUSEG.

              <button class="Mbtn-prim d-block mt-5 mx-auto" @click="goTo('WhitePaper')">
                <b>WANT TO KNOW MORE?</b>
              </button>
              </p>
            </div>
            </div>
          </div>
          <div class="d-block position-relative" >
            <img src="@/assets/images/Layer-9.webp" class="mw-100p d-block mx-auto" height="auto" width="749" alt="" 
            style="width:90%; margin-top: -11%;"
             srcset="">
          </div>
          <div class="position-relative" style="top: -37px;">
              <div class="Mswirel2  mmstwo d-none d-md-block d-lg-none">
            <img data-aos-offset="300px" data-aos="fade-left"  class='swimg1-3' src="@/assets/images/arrow-right.png" alt="">
            <img data-aos-offset="300px" data-aos="fade-left"  class='swimg1-4' src="@/assets/images/arrow-right.png" alt="">
            
            <img data-aos-offset="300px" data-aos="fade-right"  class='swimg1-1' src="@/assets/images/arrow-left.png" alt="">
            <img data-aos-offset="300px" data-aos="fade-right"  class='swimg1-2' src="@/assets/images/arrow-left.png" alt="">
        </div>
        <div class="Mswirel2 d-md-none mmsone">
            <img data-aos-offset="300px" data-aos="fade-left"  class='swimg1-3' src="@/assets/images/arrow-right.png" alt="">
            <img data-aos-offset="300px" data-aos="fade-left"  class='swimg1-4' src="@/assets/images/arrow-right.png" alt="">
            
            <img data-aos-offset="300px" data-aos="fade-right"  class='swimg1-1' src="@/assets/images/arrow-left.png" alt="">
            <img data-aos-offset="300px" data-aos="fade-right"  class='swimg1-2' src="@/assets/images/arrow-left.png" alt="">
        </div>
          </div>
          
        </div>

        

        
      </div>
    </div>
    <div class="container-fluid p-0 d-none d-lg-block" >
      <div class="row justify-content-center p-0 m-0">
        <div class="col-xl-10 col-md-12 p-0 m-0">
          <div class="mtt-5">
            <!-- <div class="col-lg-6 col-12"> -->
              <h3 class="tt-one up" >
                we fuse the security of gold <br>with the convenience of crypto
              </h3>
            <!-- </div> -->
            <!-- <div class="col-lg-6 col-12"> -->
              <div class="mt-4 position-ralative">
<p class="desc-one float-right">
  Our mission is to combine the traditionally strong foundations of physical gold together with the modern digital benefits of exciting cryptocurrency assets, such as Bitcoin, Ethereum and much more. These assets can perform in unity and in tandem at your fingertips – ensuring you never miss an outstanding crypto opportunity with the capital of gold behind you.
                       <br>
               <!-- <button-shimmer></button-shimmer> -->
                
        </p>
              </div>
              
          </div>
          
        </div>
        <div class="col-12 m-0 p-0 position-relative" style="min-height:783px;">
          
      <img class='d-none d-lg-block mw-100' height="auto" width="2743" src="@/assets/images/im1-xllow.webp"  alt="" srcset="">
      <img class='d-none d-md-block d-lg-none mw-100' height="990" width="1440" src="@/assets/images/im1-mdlow.webp"  alt="" srcset="">
      <img class='d-none d-sm-block d-md-none d-lg-none mw-100' height="768" width="1024" src="@/assets/images/im1-smlow.webp"  alt="" srcset="">
      <img class='d-block d-sm-none d-md-none d-lg-none mw-100' height="1024" width="768" src="@/assets/images/im1-xslow.webp"  alt="" srcset="">
            <!-- <img src="@/assets/images/london-tower.png" alt="" height="auto" width="2160" class="mw-100p position-absolute" srcset=""> -->
            <!-- <div class="position-absolute"> -->
            <!-- <img src="@/assets/images/b-layer.png" alt="" height="auto" width="2160" class="mw-100p position-absolute" srcset=""> -->
            <!-- <img src="@/assets/images/goldx.png" alt="" height="auto" width="705"  class="mw-100p position-absolute goldx" srcset=""> -->
            <!-- </div> -->
        </div>
        <div class="col-12 mtt-10 p-0">
          <div class="clLight d-inline-block position-relative htone" >
            <div class="position-absolute">
              <div class="tt-one-cont" style="margin-top:10%;">
              <h3 class="tt-one-c">WHY FUSE GOLD?</h3>
              </div>
              <div class="desc-one-cont">
              <p class="desc-one-c justify-text">
                The insurmountable power of gold has been felt in waves throughout societies all over the world for generations. This rare commodity has been prevalent since the dawn of time, and has come before all currencies as a form of desirable value in physical form. The magnitude and worth of this precious metal has only continued to increase in very quick time, which is why we underpin gold as the fundamental building block for all investments when it comes to longevity and growth.
        
<br>
             <button-shimmer></button-shimmer>
              </p>
            </div>
            </div>
          </div>
          <div class="d-inline-block position-relative" style="width:30%;">
            <img src="@/assets/images/Layer-11.webp" class="mw-100p" height="auto" width="749" alt="" 
            style="margin-top: -33%;margin-left: -31%;"
             srcset="">
          </div>
        </div>

        <div class="col-12 mtt-10 p-0">
          <div class="d-inline-block position-relative" style="width:30%;z-index:999;">
            <img src="@/assets/images/Layer-5.webp" class="mw-100p" height="auto" width="749" alt="" 
            style="margin: -35% 6% 0% 25%;" 
            srcset="">
          </div>
          <div class="clLight d-inline-block position-relative httwo">
          <div class="position-absolute mm-one">
              <div class="tt-one-cont">
              <h3 class="tt-one-c">THE REAL <br> DIGITAL GOLD</h3>
              </div>
              <div class="desc-one-cont">
              <p class="desc-one-d justify-text">
                Many companies offer the opportunity to purchase paper gold without any physical metal to give your purchase value. That’s why we guarantee that all the gold you buy with us translates into your own 99.999% LMBA approved gold, to manage or deliver to you at your request. Use our gold as your digital key into all available high-performing currencies and tokens.
                          <br>
             <button-shimmer></button-shimmer>
                </p>
            </div>
            </div>
          <div class="swirel1 mx-auto">
                      <img data-aos="fade-left"  class='swimg3'  src="@/assets/images/arrow-right.png" alt="">
                      <img data-aos="fade-left"  class='swimg4' src="@/assets/images/arrow-right.png" alt="">
                      
                      <img data-aos="fade-right" class='swimg1' src="@/assets/images/arrow-left.png" alt="">
                      <img data-aos="fade-right" class='swimg2' src="@/assets/images/arrow-left.png" alt="">
                  </div>
          </div>
          
        </div>


        <div class="col-12 mtt-20 p-0">
          <div class="clLight d-inline-block position-relative htthree">
          <div class="position-absolute">
              <div class="tt-one-cont" style="margin-top:10%;">
              <h3 class="tt-one-c">TRANSFORM YOUR <br>  PORTFOLIO</h3>
              </div>
              <div class="desc-one-cont">
              <p class="desc-one-c justify-text">
                Your Gold purchases become FUSE G Tokens, allowing you to diversify your portfolio with the various cryptocurrencies. You can keep adding multiple assets, switch between cryptocurrencies, convert it all back to gold or sell it all for cash whenever you choose. There are a magnitude of fully decentralised exchanges and centralised exchanges giving you around the clock freedom and flexibility., 
            
<br><br>
It’s never been easier, quicker or more secure to convert your investments into assets of your choice in order to create a brilliant online portfolio with us. We provide 24/7 information and support to answer any questions or queries you may have. We believe in clarity, and that’s why we endeavour to ensure that our customers have a full understanding of their portfolio at all times.
        
 <br>
             <button-shimmer></button-shimmer>
                </p>
            </div>
            </div>
          </div>
          <div class="d-inline-block position-relative" style="width:30%;">
            <img src="@/assets/images/Layer-8.webp" height="auto" class="mw-100p" width="747" alt="" 
            style="margin-top: -33%;margin-left: -31%;"
             srcset="">
          </div>
        </div>

        <div class="col-12 mtt-10 p-0">
          <div class="d-inline-block position-relative" style="width:30%;z-index:999;">
            <img src="@/assets/images/Layer-9.webp" height="auto" class="mw-100p" width="751" alt="" 
            style="margin: -35% 6% 0% 25%;" 
            srcset="">
          </div>
          <div class="clLight d-inline-block position-relative htfour" style="">
          <div class="position-absolute mm-one">
              <div class="tt-one-cont">
              <h3 class="tt-one-c">MAXIMUM SECURITY</h3>
              </div>
              <div class="desc-one-cont">
              <p class="desc-one-d justify-text">
                All transactions are 100% secure and guaranteed, which means you’ll never have to worry about losing money with us. We are fully legally-compliant and the safety of your assets is never in question – the moment you sign-up with us is the moment your investments and assets are yours for life. Your physical gold is held safely in maximum-secure vaults to act upon as you please, and any digital transactions you make with this gold (via FUSE G Tokens) are allocated to you via secure phrases and keys that only you will ever have access to. 
              
          <button class="btn-prim d-block mt-5" @click="goTo('WhitePaper')">
                <b>WANT TO KNOW MORE?</b> 
              </button>
               </p>
            </div>
            </div>
          <div class="swirel2">
            <img  data-aos="fade-left"  class='swimg1-3' src="@/assets/images/arrow-right.png" alt="">
            <img  data-aos="fade-left"  class='swimg1-4' src="@/assets/images/arrow-right.png" alt="">
            
            <img  data-aos="fade-right"  class='swimg1-1' src="@/assets/images/arrow-left.png" alt="">
            <img  data-aos="fade-right"  class='swimg1-2' src="@/assets/images/arrow-left.png" alt="">
        </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="mtt-30m d-lg-none">
    <footer-section></footer-section>
    </div>
    <div class="mtt-30 d-none d-lg-block">
    <footer-section></footer-section>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
  components: {
    ButtonShimmer:() => import('@/components/ButtonShimmer.vue'),
    // SwirelTwo:() => import('@/components/swirls/SwirelTwo.vue'),
    // TitleSection:() => import('@/components/TitleSection.vue'),
    // ImgSection:() => import('@/components/ImgSection.vue'),
    // CardSection:() => import('@/components/CardSection.vue'),
    // CardSectionRev:() => import('@/components/CardSectionRev.vue'),
    // CardSectionRev2:() => import('@/components/CardSectionRev2.vue'),
  },
  data() {
    return {
    }
  },
  computed:{
  },
  async mounted() {
    
  },
  methods:{
        goTo(path){ this.$router.push({name:path})},
    redir(){
      alert('redirecting')
    }
  }
}
</script>
<style scoped>
 
</style>
