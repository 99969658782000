import { isMerchant,
  getContractWallets,
  mintTokens,
  getbalanceTW,
  getBNBPrice,
    upBuyOrder,
    getContractVars,getContractWalletsBack,
    upSellOrder,
    BuyGold,
    getClientBalance,
    getPrice,
    transferFrom,
     BurnGold,
     getRefBalance,
     getTeamAddress,
    getFgBalance,
  } from './blockchain';
import {
  getClaimedDividends,
  getTotalSupply,
   getLQPoolTotalValue,
  getLQPoolBalance,
   calculateDividends,
  claimDividends,
   getTotalBurnt
} from './blockchain';
import { approveKycInit,updateRolePerms,getRolePermissions,createPermissions,createRole,removeToken,getAccessTokens,createToken,updateBarStatus,updateOrderWithdraw,updateOrderDeposit,updateVaultAddress,
  deleteVaultAddress,
  updateGoldOrder,
  getVaultAddresses,
  addVaultAddress,
  getGoldOrders,
  createGoldOrder,
  uploadPDFOrder,
  activateMerchant,
  getPayments,
  updateMerchant,
  updatePromoter,
  addPromoter,
  getPromoters,
  cancelUserOrder,
  loadDefaultSettings,
  cancelOrder,
  updateUserKyc,
  getSearchedUser,
  readPdf,
  requestTfaReset,
  verifyTfa,
  setTFA,
  checkUserKyc,
  getKycPdf,
  nextKyc,
  initKyc,
  submitKyc,
  deleteUser,
  rejectKyc,
  approveKyc,
  checkKycStatus,
  registerClient,
  getRegTypes } from './kyc';
import {
  checkReferralID,checkReferral,sendVerifEmail,getNickName,migrateUser,checkRefe,addEM, deleteEM,getWemails,clearData,getPriceFeed,getRewardConfigLive,getRewardConfig,removeContractWallet,addContractWallet,updateRewards,dltProm,updateUsernames,sendQrCode,
  fetchRegisterInterest,
  deleteCW,
  createCW,
  getPromUsersPro,getPromUsers,
  addRegInterest,
  setMetrics,
  addWithdraw,
  addActivity,
  getActivities,
  updateWhiteToken,
  deleteWhiteToken,
  getWhiteTokens,
  addWhiteToken,
  activeMerchant,
  update2faStatusAdmin,
  checkRef,
  getPaymntInfo,
  getPaymentData,
  getUserInfo,
  setBuyOrderProgress,
  setSellOrderProgress,
  updateCounter,
  getConter,
  getClientOrdersHistory,
  getClientOrders,
  updateNumber,
  addLog,
  notifyU,
  notify,
  updateAvtar,
  updateNoti,
  fetchNotifications,
  verifyEmailProm,
  verifyEmail,
  updateSettings,
  getSettings,
  loadTestUser,
  deleteUserAdmin,
  lockUser,
  verifyTfaLogin,
  update2faStatus,
  sendMail,
  updatePassword,
  sendPasswordResetCode,
  getKycRecords,
  InitPayIntent,
  getSellOrders,
  getBuyOrders,
  placeSellOrder,
   placeBuyOrder,
  getUserTypes,
   addAdminUser,
   getAllUsers,
   updateUserPermission,
  updateUser,
  checkUserAuth,
  logout,
  login} from './backend'
import {getEvents} from './bitquerry'
export default {
  getEvents, 
  BurnGold,
  isMerchant,
  getContractWallets,
  mintTokens,
  getClientBalance,
  getPrice,
  transferFrom,
  getbalanceTW,
  getBNBPrice,
  upBuyOrder,
  upSellOrder,
  BuyGold,
  getClaimedDividends,
  getTotalSupply,
  getLQPoolTotalValue,
  getLQPoolBalance,
  calculateDividends,
  claimDividends,
  getTotalBurnt,
  getRefBalance,
  getTeamAddress,
  getFgBalance,
  checkReferralID,checkReferral,sendVerifEmail,getNickName,migrateUser,checkRefe,addEM, deleteEM,getWemails,clearData,getPriceFeed,getRewardConfigLive,getRewardConfig,removeContractWallet,addContractWallet,updateRewards,dltProm,updateUsernames,sendQrCode,
  fetchRegisterInterest,
  deleteCW,
  createCW,
  getPromUsersPro,getPromUsers,
  addRegInterest,
  setMetrics,
  addWithdraw,
  addActivity,
  getActivities,
  updateWhiteToken,
  deleteWhiteToken,
  getWhiteTokens,
  addWhiteToken,
  activeMerchant,
  update2faStatusAdmin,
  checkRef,
  getPaymntInfo,
  getPaymentData,
  getUserInfo,
  setBuyOrderProgress,
  setSellOrderProgress,
  updateCounter,
  getConter,
  getClientOrdersHistory,
  getClientOrders,
  updateNumber,
  addLog,
  notifyU,
  notify,
  updateAvtar,
  updateNoti,
  fetchNotifications,
  verifyEmailProm,
  verifyEmail,
  updateSettings,
  getSettings,
  loadTestUser,
  approveKycInit,updateRolePerms,getRolePermissions,createPermissions,createRole,removeToken,getAccessTokens,createToken,updateBarStatus,updateOrderWithdraw,updateOrderDeposit,updateVaultAddress,
  deleteVaultAddress,
  updateGoldOrder,
  getVaultAddresses,
  addVaultAddress,
  getGoldOrders,
  createGoldOrder,
  uploadPDFOrder,
  activateMerchant,
  getPayments,
  updateMerchant,
  updatePromoter,
  addPromoter,
  getPromoters,
  cancelUserOrder,
  loadDefaultSettings,
  cancelOrder,
  updateUserKyc,
  getSearchedUser,
  readPdf,
  requestTfaReset,
  verifyTfa,
  setTFA,
  getContractVars,getContractWalletsBack,
  checkUserKyc,
  getKycPdf,
  nextKyc,
  initKyc,
  submitKyc,
  deleteUser,
  rejectKyc,
  approveKyc,
  checkKycStatus,
  registerClient,
  getRegTypes,
  deleteUserAdmin,
  lockUser,
  verifyTfaLogin,
  update2faStatus,
  sendMail,
  updatePassword,
  sendPasswordResetCode,
  getKycRecords,
  InitPayIntent,
  getSellOrders,
  getBuyOrders,
  placeBuyOrder,
  placeSellOrder,
  updateUserPermission,
  updateUser,
  checkUserAuth,
  logout,
  login,
  getUserTypes,
  addAdminUser,
  getAllUsers
};