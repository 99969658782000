import state from '../state' 
var states = state;
const key = "BQYtjOPEU0TldLQE7G3QXApXU4mVpjkh"
//base currency will be target main currency

//quote currency target currency


const getBNBPrice = async(context) => {
    BqueryBSC("bsc",'0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c','0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56');
}
const getLINKPrice = async() => {
    BqueryETH("ethereum",'0x514910771AF9Ca656af840dff83E8264EcF986CA','0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48','LINK');
    
}
const getETHPrice = async() => {
    BqueryETH("ethereum",'0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2','0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48','ETH');
    
}

const BqueryBSC =  async (network, base, quote) => {
    // let network = null;
    // if (context.state.userAccount.selectedChain.chainId == "0x1") network = "ethereum"
    // if (context.state.userAccount.selectedChain.chainId == "0x4") network = "ethereum"
    // if (context.state.userAccount.selectedChain.chainId == "0x38") network = "bsc"
    // if (context.state.userAccount.selectedChain.chainId == "0x61") network = "bsc_testnet"
    const query = `
    {
        ethereum(network: ${network}) {
          dexTrades(
            options: {desc: ["block.height","tradeIndex"], limit: 1}
            exchangeName: {in: ["Pancake", "Pancake v2"]}
            baseCurrency: {is: "${base}"}
            quoteCurrency: {is: "${quote}"}
            date: {after: "2022-01-02"}
          ) {
            transaction {
              hash
            }
            tradeIndex
            smartContract {
              address {
                address
              }
              contractType
              currency {
                name
              }
            }
            tradeIndex
            block {
              height
            }
            baseCurrency {
              symbol
              address
            }
            quoteCurrency {
              symbol
              address
            }
            quotePrice
         
          }
        }
      }
`;
const url = "https://graphql.bitquery.io/";
const opts = {
  method: "POST",
  headers: {
      "Content-Type": "application/json",
    "X-API-KEY": key
  },
  body: JSON.stringify({
      query
  })
};
try {
fetch(url, opts)
  .then(res => res.json())
  .then(async (res) => { 
    states.BNB =  res.data.ethereum.dexTrades[0].quotePrice
   })
  .catch(console.error);  
} catch (error) {
console.log("Bitquery Error:", error)
}

  }
  const BqueryETH =  async (network, base, quote, vr) => {
    // let network = null;
    // if (context.state.userAccount.selectedChain.chainId == "0x1") network = "ethereum"
    // if (context.state.userAccount.selectedChain.chainId == "0x4") network = "ethereum"
    // if (context.state.userAccount.selectedChain.chainId == "0x38") network = "bsc"
    // if (context.state.userAccount.selectedChain.chainId == "0x61") network = "bsc_testnet"
    const query = `
    {
        ethereum(network: ${network}) {
          dexTrades(
            options: {desc: ["block.height","tradeIndex"], limit: 1}
            exchangeName: {in: ["Uniswap", "Uniswap v2"]}
            baseCurrency: {is: "${base}"}
            quoteCurrency: {is: "${quote}"}
            date: {after: "2022-01-02"}
          ) {
            transaction {
              hash
            }
            tradeIndex
            smartContract {
              address {
                address
              }
              contractType
              currency {
                name
              }
            }
            tradeIndex
            block {
              height
            }
            baseCurrency {
              symbol
              address
            }
            quoteCurrency {
              symbol
              address
            }
            quotePrice
         
          }
        }
      }
`;
const url = "https://graphql.bitquery.io/";
const opts = {
  method: "POST",
  headers: {
      "Content-Type": "application/json",
    "X-API-KEY": key
  },
  body: JSON.stringify({
      query
  })
};
try {
fetch(url, opts)
  .then(res => res.json())
  .then(async (res) => { 
    // console.log("Bitquery Resp:", res);
    states[vr] = res.data.ethereum.dexTrades[0].quotePrice;
   })
  .catch(console.error);  
} catch (error) {
console.log("Bitquery Error:", error)
}

  }
  const getEvents =  async () => {
    await blacklisted()
    await unBlacklisted()
    // let network = null;
    // if (context.state.userAccount.selectedChain.chainId == "0x1") network = "ethereum"
    // if (context.state.userAccount.selectedChain.chainId == "0x4") network = "ethereum"
    // if (context.state.userAccount.selectedChain.chainId == "0x38") network = "bsc"
    // if (context.state.userAccount.selectedChain.chainId == "0x61") network = "bsc_testnet"
 

  }
  async function blacklisted(){
    const query =`
    {
      ethereum(network: bsc_testnet) {
        smartContractEvents(
          options: {limit: 10}
          smartContractAddress: {is: "0xb465b0880Fb7a2442920fd17cFe8d030F04f1b2E"}
          smartContractEvent: {is: "Blacklisted"}
        ) {
          arguments {
            value
            argument
            argumentType
            index
          }
          block(height: {gt: 21714670}) {
            height
          }
        }
      }
    }`;
const url = "https://graphql.bitquery.io/";
const opts = {
  method: "POST",
  headers: {
      "Content-Type": "application/json",
    "X-API-KEY": key
  },
  body: JSON.stringify({
      query
  })
};
try {
fetch(url, opts)
  .then(res => res.json())
  .then(async (res) => { 
    states.blacklistedWallets = []
    res.data.ethereum.smartContractEvents.forEach(element => {
      console.log("in loop", element)

      states.blacklistedWallets.push(element.arguments[0].value)
    });
   })
  .catch(console.error);  
} catch (error) {
console.log("Bitquery Error:", error)
}
  }
  async function unBlacklisted(){
    const query =`
    {
      ethereum(network: bsc_testnet) {
        smartContractEvents(
          options: {limit: 10}
          smartContractAddress: {is: "0xb465b0880Fb7a2442920fd17cFe8d030F04f1b2E"}
          smartContractEvent: {is: "UnBlacklisted"}
        ) {
          arguments {
            value
            argument
            argumentType
            index
          }
          block(height: {gt: 21714670}) {
            height
          }
        }
      }
    }`;
const url = "https://graphql.bitquery.io/";
const opts = {
  method: "POST",
  headers: {
      "Content-Type": "application/json",
    "X-API-KEY": key
  },
  body: JSON.stringify({
      query
  })
};
try {
fetch(url, opts)
  .then(res => res.json())
  .then(async (res) => { 
    states.unBlacklistedWallets = []
    res.data.ethereum.smartContractEvents.forEach(element => {
      console.log("in loop", element)
      states.unBlacklistedWallets.push(element.arguments[0].value)
    });
   })
  .catch(console.error);  
} catch (error) {
console.log("Bitquery Error:", error)
}
  }
 export {getEvents} 

