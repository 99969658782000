import Vue from 'vue'
import store from '../store'
function lazyLoad(view){
  return() => import(`../views/${view}.vue`)
}

import Home from '../views/Home.vue';
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  { path: '/dashboard/admin', redirect: { name: 'UserManagement' }, meta:{ wallet:true,auth: true,allowedUsers:[4,5] } },
  {
    path: '/',
    name: 'Home',
    meta:{auth: false},
    component: Home
  },
  {
    path: '/referral/:ref',
    name: 'HomeRef',
    meta:{auth: false},
    component: Home
  },
  {
    path: '/partners',
    meta:{auth: false},
    name: 'Partners',
    component: lazyLoad("Partners")
  },
  {
    path: '/leaderboard',
    meta:{auth: false},
    name: 'Leaderboard',
    component: lazyLoad("Leaderboard")
  },
  
  {
    path: '/thankyou',
    meta:{auth: false},
    name: 'thankyou',
    component: lazyLoad("ThankYou")
  },
  {
    path: '/verified',
    meta:{auth: false},
    name: 'verified',
    component: lazyLoad("Verified")
  },
  {
    path: '/email-verification/:id',
    name: 'Email',
    meta:{auth: false},
    component: Home
  },

  {
    path: '/security-custody',
    meta:{auth: false},
    name: 'SecurityCustody',
    component: lazyLoad("SecurityCustody")
  },
  {
    path: '/key-features',
    meta:{auth: false},
    name: 'WhitePaper',
    component: lazyLoad("WhitePaper")
  },
  {
    path: '/privacy-policy',
    meta:{auth: false},
    name: 'PrivacyPolicy',
    component: lazyLoad("PrivacyPolicy")
  },
  {
    path: '/terms-conditions',
    meta:{auth: false},
    name: 'TermsConditions',
    component: lazyLoad("TermsConditions")
  },
  {
    path: '/goldx-terms-conditions',
    meta:{auth: false},
    name: 'GoldxTermsConditions',
    component: lazyLoad("GoldxTermsConditions")
  },
  {
    path: '/page-not-found',
    meta:{auth: false},
    name: '404',
    component: lazyLoad("404")
  },
  {
    path: '/cookie-policy',
    meta:{auth: false},
    name: 'CookiePolicy',
    component: lazyLoad("CookiePolicy")
  },
  {
    path: '/website-acceptable-use-policy',
    meta:{auth: false},
    name: 'Website Acceptable Use Policy',
    component: lazyLoad("WAUP")
  },
  {
    path: '/website-disclaimer',
    meta:{auth: false},
    name: 'Website Disclaimer',
    component: lazyLoad("WebsiteDisclaimer")
  },
  {
    path: '/ico-terms-and-conditions',
    meta:{auth: false},
    name: 'ICO Terms and Conditions',
    component: lazyLoad("ICOTAC")
  },
  {
    path: '/gdpr-privacy-policy',
    meta:{auth: false},
    name: 'GDPR Privacy Policy',
    component: lazyLoad("GDPRPP")
  },
  {
    path: '/newsroom',
    name: 'Newsroom',
    meta:{auth: false},
    component: lazyLoad("Newsroom")
  },
  {
    path: '/markets',
    meta:{auth: false},
    name: 'Markets',
    component: lazyLoad("Markets")
  },
  // Dashboard Routes
  {
    path: '/dashboard/kyc',
    name: 'KycDashboard',
    component: lazyLoad("KycDashboard"),
    meta:{ wallet:true,auth: true,allowedUsers:["kyc","super"],slug:"kyc" }
  },
  {
    path: '/dashboard/kyc/:username',
    name: 'KycDashboardSearch',
    component: lazyLoad("KycDashboard"),
    meta:{ wallet:true,auth: true,allowedUsers:["kyc","super"],slug:"kyc" }
  },
  {
    path: '/dashboard/merchant',
    name: 'MarchantDashboard',
    component: lazyLoad("MarchantDashboard"),
    meta:{ wallet:true,auth: true,allowedUsers:["merchant","super"],slug:"merchant" }
  },
  {
    path: '/dashboard/merchant/profile',
    name: 'MarchantDashboardProfile',
    component: lazyLoad("DashboardProfile"),
    meta:{ wallet:true,auth: true,allowedUsers:["profile","merchant","super"],slug:"merchant" }
  },
  {
    path: '/dashboard/kyc/profile',
    name: 'MarchantDashboardProfile',
    component: lazyLoad("DashboardProfile"),
    meta:{ wallet:true,auth: true,allowedUsers:["kyc","super","profile"],slug:"merchant" }
  },
  {
    path: '/dashboard/admin/profile',
    name: 'AdminDashboardProfile',
    component: lazyLoad("DashboardProfile"),
    meta:{ wallet:true,auth: true,allowedUsers:["admin","super","profile"],slug:"merchant" }
  },
  {
    path: '/dashboard/merchant/exchange',
    name: 'MarchantDashboardExchange',
    component: lazyLoad("DashboardExchange"),
    meta:{ wallet:true,auth: true,allowedUsers:["merchant","super"],slug:"merchant" }

  },
  {
    path: '/dashboard/client/profile',
    name: 'ClientDashboardProfile',
    component: lazyLoad("DashboardProfile"),
    meta:{ wallet:true,auth: true,allowedUsers:["client","profile"],slug:"client" }

  },
  {
    path: '/dashboard/client/exchange',
    name: 'ClientDashboardExchange',
    component: lazyLoad("DashboardExchange"),
    meta:{ wallet:true,auth: true,allowedUsers:["client"],slug:"client"}
  },
  {
    path: '/dashboard/client',
    name: 'ClientDashboard',
    component: lazyLoad("ClientDashboard"),
    meta:{ wallet:true,auth: true,allowedUsers:["client"],slug:"client" }
  },
  {
    path: '/dashboard/client/payment/:id',
    name: 'ClientDashboard',
    component: lazyLoad("ClientDashboard"),
    meta:{ wallet:true,auth: true,allowedUsers:["client"],slug:"client" }
  },
  {
    path: '/dashboard/admin/user-management',
    name: 'UserManagement',
    component: lazyLoad("UserManagement"),
    meta:{ wallet:true,auth: true,allowedUsers:["admin","super"],slug:"admin" }
  },
  {
    path: '/dashboard/admin/user-management/:username',
    name: 'UserManagementSearch',
    component: lazyLoad("UserManagement"),
    meta:{ wallet:true,auth: true,allowedUsers:["admin","super"],slug:"admin" }
  },
  {
    path: '/dashboard/admin/settings',
    name: 'Settings',
    component:  lazyLoad("Settings"),
    meta:{ wallet:true,auth: true,allowedUsers:["super"],slug:"admin" }
  },
  
  {
    path: '/dashboard/admin/contract-settings',
    name: 'ContractSettings',
    component:  lazyLoad("ContractSettings"),
    meta:{ wallet:true,auth: true,allowedUsers:["super","owner"],slug:"admin" }
  },
  {
    path: '/dashboard/admin/gold-minting',
    name: 'Gold Custody & Minting',
    component:  lazyLoad("GoldMinting"),
    meta:{ wallet:true,auth: true,allowedUsers:["super"],slug:"admin" }
  },
  {
    path: '/dashboard/admin/register-interest',
    name: 'Register Interest',
    component:  lazyLoad("RegisterInterest"),
    meta:{ wallet:true,auth: true,allowedUsers:["super"],slug:"admin" }
  },
  {
    path: '/dashboard/admin/payments',
    name: 'SuperSettings',
    component:  lazyLoad("SuperSettings"),
    meta:{ wallet:true,auth: true,allowedUsers:["super","merchant"],slug:"admin" }
  },
  {
    path: '/dashboard/notifications',
    name: 'Notifications',
    component: lazyLoad("Notifications"),
    meta:{ wallet:true,auth: true,allowedUsers:["notifications",'client'],slug:"admin" }
  },
  {
    path: '/dashboard/admin/api-keys',
    name: 'APIKeys',
    component: lazyLoad("APIKeys"),
    meta:{ wallet:true,auth: true,allowedUsers:["admin","super"],slug:"admin" }
  },
  {
    path: '/dashboard/admin/email-smtp',
    name: 'EmailSMTP',
    component: lazyLoad("EmailSMTP"),
    meta:{ wallet:true,auth: true,allowedUsers:["admin","super"],slug:"admin" }
  },
  {
    path: '/dashboard/admin/automated-tasks',
    name: 'AutomatedTasks',
    component: lazyLoad("AutomatedTasks"),
    meta:{ wallet:true,auth: true,allowedUsers:["admin","super"],slug:"admin" }
  },
  {
    path: '/dashboard/admin/health-diagnostics',
    name: 'HealthDiagnostics',
    component: lazyLoad("HealthDiagnostics"),
    meta:{ wallet:true,auth: true,allowedUsers:["admin","super"],slug:"admin" }
  },
  

 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
}
})
router.beforeEach(async (to, from, next) => {
  store.state.routeLoader = true
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  // console.log(vw)
  if (vw < 1200) store.state.sidebar = false 
  // console.log("loader triggered")
await store.dispatch('checkUserAuth');
// store.state.routeLoader = true
  if(to.meta.auth && store.state.user){
    let allowed = false;
    to.meta.allowedUsers.forEach(element => {
      // console.log('element from meta of router', element)
      if(store.state.user.permissions[element]){
          allowed = true
        }  
    });
    if (allowed) {
      // setTimeout(() => {
        next()        
      // }, 3000);
    }else{
      next(from.path)
    }    
    
  }else{
    next()
  }
  
})
router.afterEach((to, from, next) => {
  store.state.routeLoader = false
  console.log("loader triggered OFF")

})

export default router
