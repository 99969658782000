import Vue from 'vue'
import './assets/main.css'
import './assets/dark.css'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false
import AOS from 'aos'
import Swal from 'sweetalert2'
import io from "socket.io-client"
import 'aos/dist/aos.css'
import SVG from '@/components/SVG.vue'
Vue.component('svg-comp', SVG);
import VueClazyLoad from 'vue-clazy-load'
import FooterSection from '@/components/FooterSection.vue'
Vue.component('footer-section', FooterSection);
Vue.config.productionTip = false
import VueConfetti from 'vue-confetti'
import VueJsonToCsv from 'vue-json-to-csv'
Vue.component('vue-json-to-csv', VueJsonToCsv);
import { validate } from 'bitcoin-address-validation';
Vue.use(VueConfetti)
Vue.use(VueClazyLoad)
new Vue({
  router,
  store,
  data:{
    Swal,
    render:false,
    BTC:validate,
    Brender:false,
    Toast:null,
    socket : io(),
    // socket : io("localhost:3030")  
  },
  mounted() { 
    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 6000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    AOS.init(
    {
      // Global settings:
      offset: 20, // offset (in px) from the original trigger point
      delay: 400, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    }
  ) },
  render: h => h(App)
}).$mount('#app')
